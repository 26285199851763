import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import cloneDeep from 'lodash-es/cloneDeep';
import { Enumerations } from 'src/app/models/Enumerations';
import { Supplier } from 'src/app/models/Supplier';
import { SupplierPartner } from 'src/app/models/SupplierPartner';
import { Coffee } from 'src/app/models/Coffee';
import { Constants } from '../constants';
import { Blend } from 'src/app/models/Blend';
import { User } from 'src/app/models/User';
import { IdAndLabelArrayOrNotnull } from 'src/app/modules/roast/filters/filter.component';
import { Utils2 } from '../utils2';
import { DateTime } from 'luxon';
import { Roast, RoastTemplate } from 'src/app/models/Roast';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';

export type HiddenType = 'only' | 'true' | 'false';

export interface RoastAverages {
    [key: string]: { loss: number, rsize: number, avgbatch: number };
}

export interface RoastFilterInfo {
    amount: { min: number, max: number, step: number, unit: string, vals?: string[] },
    AUC: { min: number, max: number, step: number, unit: string, vals?: string[] },
    AUC_base: { min: number, max: number, step: number, unit: string, vals?: string[] },
    batch_number: { min: number, max: number, step: number, vals?: string[] },
    batch_prefix: { vals: string[] },
    blends: { vals: Blend[] },
    BTU_batch: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // BTU_bbp: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // BTU_cooling: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // BTU_ELEC: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // BTU_LPG: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // BTU_NG: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // BTU_preheat: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // BTU_roast: { min: number, max: number, step: number, unit: string, vals?: string[] },
    charge_temp: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // charge_temp_ET: { min: number, max: number, step: number, unit: string, vals?: string[] },
    CM_BTD: { min: number, max: number, step: number, unit: string, vals?: string[] },
    CM_ETD: { min: number, max: number, step: number, unit: string, vals?: string[] },
    CO2_batch: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // CO2_bbp: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // CO2_cooling: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // CO2_preheat: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // CO2_roast: { min: number, max: number, step: number, unit: string, vals?: string[] },
    coffees: { vals: Coffee[] },
    color_system: { vals: string[] },
    date: { min: DateTime, max: DateTime },
    density_roasted: { min: number, max: number, step: number, unit: string, vals?: string[] },
    destroyed: boolean,
    DEV_ratio: { min: number, max: number, step: number, unit: string, vals?: string[] },
    DEV_time: { min: number, max: number, step: number, unit: string, vals?: string[] },
    discarded: boolean,
    drop_temp: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // drop_temp_ET: { min: number, max: number, step: number, unit: string, vals?: string[] },
    drop_time: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // DRY_temp: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // DRY_time: { min: number, max: number, step: number, unit: string, vals?: string[] },
    end_weight: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // FCe_temp: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // FCe_time: { min: number, max: number, step: number, unit: string, vals?: string[] },
    FCs_RoR: { min: number, max: number, step: number, unit: string, vals?: string[] },
    FCs_temp: { min: number, max: number, step: number, unit: string, vals?: string[] },
    FCs_time: { min: number, max: number, step: number, unit: string, vals?: string[] },
    ground_color: { min: number, max: number, step: number, vals?: string[] },
    humidity: { min: number, max: number, step: number, unit: string, vals?: string[] },
    ID: { min: number, max: number, step: number, vals?: string[] },
    machine: { vals: string[] },
    moisture: { min: number, max: number, step: number, unit: string, vals?: string[] },
    notes: { vals?: string[] },
    pressure: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // setup: { vals: string[] },
    temperature: { min: number, max: number, step: number, unit: string, vals?: string[] },
    template: { vals: { pre: string, num: string, label: string, id: string }[] },
    // TP_temp: { min: number, max: number, step: number, unit: string, vals?: string[] },
    // TP_time: { min: number, max: number, step: number, unit: string, vals?: string[] },
    users: { vals: User[] },
    whole_color: { min: number, max: number, step: number, vals?: string[] },
}

export type NumberFilterType = {
    min?: number,
    max?: number,
    inverse?: boolean,
    allowNull?: 'yes' | 'only' | 'no',
    firstInit?: boolean,
    vals?: number[],
};

// replaced by IdAndLabelArrayOrNotnull<T>
// export type StringArrayOrNotNull = {
//     vals?: string[],
//     notnull?: boolean,
// };

export interface FilterOptions {
    // created_at?: Date;
    // updated_at?: Date;
    // deleted?: boolean;
    // owner?: Account;
    // created_by?: User;
    // updated_by?: User;
    filtername?: string, // name of the filter for saving/loading
    _id?: string, // _id of the saved filter, needed to be able to delete a CustomFilter containing this FilterOptions
    filtertype?: 'Roast', // type of the filter for saving/loading
    anpa?: boolean,
    label?: string, // roast label filter
    ssf?: { _id: string, hr_id?: string, label?: string }[] | 'all',
    origins?: IdAndLabelArrayOrNotnull<string>,
    showOrganic?: 'on' | 'off' | '',
    showHidden?: HiddenType,
    from?: DateTime,
    to?: DateTime,

    pickedYear?: number,

    roast_id?: IdAndLabelArrayOrNotnull<string>,
    amount?: NumberFilterType,
    AUC?: NumberFilterType,
    AUC_base?: NumberFilterType,
    batch_number?: NumberFilterType,
    batch_prefix?: string[],
    blends?: IdAndLabelArrayOrNotnull<Blend>,
    BTU_batch?: NumberFilterType,
    // BTU_bbp?: NumberFilterType,
    // BTU_cooling?: NumberFilterType,
    // BTU_ELEC?: NumberFilterType,
    // BTU_LPG?: NumberFilterType,
    // BTU_NG?: NumberFilterType,
    // BTU_preheat?: NumberFilterType,
    // BTU_roast?: NumberFilterType,
    charge_temp?: NumberFilterType,
    // charge_temp_ET?: NumberFilterType,
    CM_BTD?: NumberFilterType,
    CM_ETD?: NumberFilterType,
    CO2_batch?: NumberFilterType,
    // CO2_bbp?: NumberFilterType,
    // CO2_cooling?: NumberFilterType,
    // CO2_preheat?: NumberFilterType,
    // CO2_roast?: NumberFilterType,
    coffees?: IdAndLabelArrayOrNotnull<Coffee>,
    color_systems?: string[],
    cupping_notes?: { allowNull?: 'yes' | 'only' | 'no', vals?: string[] },
    cupping_score?: NumberFilterType,
    density_roasted?: NumberFilterType,
    // destroyed: boolean,
    DEV_ratio?: NumberFilterType,
    DEV_time?: NumberFilterType,
    discarded?: boolean, // true: must be set; false: false or not set (null)
    drop_temp?: NumberFilterType,
    // drop_temp_ET?: NumberFilterType,
    drop_time?: NumberFilterType,
    // DRY_temp?: NumberFilterType,
    // DRY_time?: NumberFilterType,
    end_weight?: NumberFilterType,
    // FCe_temp?: NumberFilterType,
    // FCe_time?: NumberFilterType,
    FCs_RoR?: NumberFilterType,
    FCs_temp?: NumberFilterType,
    FCs_time?: NumberFilterType,
    ground_color?: NumberFilterType,
    humidity?: NumberFilterType,
    ID?: NumberFilterType,
    machine?: string[],
    moisture?: NumberFilterType,
    notes?: { allowNull?: 'yes' | 'only' | 'no', vals?: string[] },
    pressure?: NumberFilterType,
    reconciled?: boolean, // true: must be set; false: false or not set (null)
    temperature?: NumberFilterType,
    is_template?: boolean,
    // templates?: { pre?: string, num?: number }[] | 'notnull',
    // TP_temp?: NumberFilterType,
    // TP_time?: NumberFilterType,
    // users?: ???,
    whole_color?: NumberFilterType,
}

export interface GetPageOptions extends FilterOptions {
    pageSize?: number,
    pageIndex?: number,
    sortOrder?: string,
    inverse?: boolean,
    limit?: number,
}


@Injectable({
    providedIn: 'root'
})
export class StandardService {

    static filterOptionsRenameMap = new Map<string, string>();

    constructor(
        private http: HttpClient,
        private utils2: Utils2,
        private userService: UserService,
    ) {
        StandardService.filterOptionsRenameMap.set('pageSize', 'limit');
        StandardService.filterOptionsRenameMap.set('sortOrder', 'sort');
    }

    addModelOptions(params: HttpParams, modelpath: string): HttpParams {
        if (modelpath === 'stores' || modelpath === 'locations') {
            params = params.set('v', environment.latestWebVersion);
        }
        return params;
    }

    /**
     * Adds all options to the given HttpParams
     * @param {HttpParams} params HttpParams, e.g. created with new HttpParams()
     * @param {GetPageOptions} options options to be sent
     * @returns {HttpParams} new HttpParams object
     */
    addFilterOptions(params: HttpParams, options?: GetPageOptions): HttpParams {
        const optionsCopy = cloneDeep(options);
        this.utils2.cleanResult(optionsCopy);
        if (!optionsCopy) {
            return params;
        }
        
        const specialTreatment = ['ssf', 'pageIndex', 'showHidden', 'showOrganic', 'from', 'to', 'anpa'];
        
        const ssfStr = optionsCopy.ssf === 'all' ? undefined : optionsCopy.ssf?.filter(l => l?._id).map(l => l._id.toString()).join(Constants.SSF_SEPARATOR);
        if (ssfStr) {
            params = params.set('ssf', ssfStr);
        }
        if (optionsCopy.showHidden === 'only') {
            params = params.set('hidden', 'only');
        } else if (optionsCopy.showHidden === 'true') {
            params = params.set('hidden', 'true');
        } // not set means 'false'
        if (optionsCopy.showOrganic) {
            params = params.set('certType', (optionsCopy.showOrganic === 'on' ? Enumerations.CertificationTypes.ORGANIC : Enumerations.CertificationTypes.NOT_ORGANIC).toString());
        }
        if (optionsCopy.anpa) {
            params = params.set('anpa', '1');
        }
        if (optionsCopy.from?.isValid && optionsCopy.to?.isValid) {
            params = params.set('from', optionsCopy.from.valueOf().toString());
            params = params.set('to', optionsCopy.to.valueOf().toString());
        }
        if (typeof optionsCopy.pageIndex !== 'undefined') {
            params = params.set('page', (optionsCopy.pageIndex + 1).toString());
        }

        // treat pageSize, sortOrder, inverse, coffees and all others
        const props = Object.getOwnPropertyNames(optionsCopy);
        for (let p = 0; p < props.length; p++) {
            const prop = props[p];
            if (specialTreatment.includes(prop)) {
                continue;
            }
            const propName = StandardService.filterOptionsRenameMap.get(prop) || prop;
            if (typeof optionsCopy[prop] !== 'undefined') {
                // TODO avoid sending it if it is the default
                if (typeof optionsCopy[prop] === 'string' || typeof optionsCopy[prop] === 'number') {
                    params = params.set(propName, optionsCopy[prop]);
                } else if (typeof optionsCopy[prop] === 'boolean') {
                    params = params.set(propName, optionsCopy[prop] ? '1' : '0');
                // } else if (optionsCopy[prop].length) {
                //     params = params.set(propName, optionsCopy[prop].join(Constants.FILTERVALUE_SEPARATOR));
                } else {
                    params = params.set(propName, JSON.stringify(optionsCopy[prop]));
                }
            }
        }
        return params;
    }

    getDeleted<T>(modelpath: string): Observable<{ success: boolean, result: T[], count: number, v: string, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/deleted/' + modelpath;
        let params = new HttpParams();
        params = this.addModelOptions(params, modelpath);
        return this.http.get<{ success: boolean, result: T[], count: number, v: string, error: string }>(url, { params });
    }

    // TODO also returns replblends
    getStocked(): Observable<{ success: boolean, result: { coffees: Coffee[], blends: Blend[] }, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/beansstock';
        return this.http.get<{ success: boolean, result: { coffees: Coffee[], blends: Blend[] }, error: string }>(url);
    }

    getRoastAverages(beans: Coffee, blend: Blend, machine: string): Observable<{ success: boolean, result: RoastAverages, error: string }> {
        let params = new HttpParams();
        if (beans?._id) {
            params = params.set('id', beans._id);
        } else if (blend?.label) {
            params = params.set('blend', blend.label);
        }
        if (machine) {
            params = params.set('machine', machine);
        }
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastaverages';
        return this.http.get<{ success: boolean, result: RoastAverages, error: string }>(url, { params });
    }

    getRoastTemplates(beans: Coffee, blend: Blend): Observable<{ success: boolean, result: ((RoastTemplate | Roast) & { roast_id?: string })[], error: string }> {
        let params = new HttpParams();
        if (beans?._id) {
            params = params.set('id', beans._id);
        } else if (blend?.label) {
            params = params.set('blend', blend.label);
        }
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roasttemplates';
        return this.http.get<{ success: boolean, result: ((RoastTemplate | Roast) & { roast_id?: string })[], error: string }>(url, { params });
    }

    getAll<T>(modelpath: string, options: GetPageOptions = {}): Observable<{ success: boolean, result: T[], count: number, v: string, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/' + modelpath;
        let params = new HttpParams();
        params = this.addModelOptions(params, modelpath);
        params = this.addFilterOptions(params, options);
        return this.http.get<{ success: boolean, result: T[], count: number, v: string, error: string }>(url, { params });
    }

    getOne<T>(modelpath: string, id: string): Observable<{ success: boolean, result: T, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/' + modelpath + '/' + id;
        let params = new HttpParams();
        params = this.addModelOptions(params, modelpath);
        return this.http.get<{ success: boolean, result: T, error: string }>(url, { params });
    }

    getPage2<T>(modelpath: string, options: GetPageOptions = {}): Observable<{ success: boolean, result: T[], count: number, error: string }> {
        // this.addDefaultsToFilterOptions(options);
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/' + modelpath;
        let params = new HttpParams();
        params = this.addModelOptions(params, modelpath);
        params = this.addFilterOptions(params, options);

        return this.http.get<{ success: boolean, result: T[], count: number, error: string }>(url, { params });
    }

    getPage<T>(modelpath: string,
        pageSize = 10, pageIndex = 0,
        sortOrder: string, inverse = false,
        ssf: { _id: string }[] | 'all' = 'all',
        filter?: string,
        getHidden: HiddenType = 'false',
        getOrganic?: 'on' | 'off' | '',
        from?: DateTime,
        to?: DateTime,
        origins?: string[] | 'notnull')
        : Observable<{ success: boolean, result: T[], count: number, error: string }> {

        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/' + modelpath;
        let params = new HttpParams();
        params = this.addModelOptions(params, modelpath);
        const originParam = origins == null ? undefined : { notnull: origins === 'notnull', vals: origins === 'notnull' ? [] : origins };
        params = this.addFilterOptions(params, { pageSize, pageIndex, sortOrder, inverse, ssf, showHidden: getHidden, showOrganic: getOrganic, origins: originParam, from, to });
        if (filter) {
            params = params.set('filter', filter);
        }
        return this.http.get<{ success: boolean, result: T[], count: number, error: string }>(url, { params });
    }

    getPageForId2<T>(modelpath: string, pageSize: number, _id: string, options: GetPageOptions = {}): Observable<{ success: boolean, result: { objects: T[], pageIndex: number }, count: number, error: string }> {
        // return this.getPageForId<T>(modelpath, pageSize, _id, options.sortOrder, options.inverse, options.ssf, undefined, false, options.showOrganic, options.date, options.origins);
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/' + modelpath + '/page/' + _id;
        let params = new HttpParams();
        params = this.addModelOptions(params, modelpath);
        params = this.addFilterOptions(params, options);
        return this.http.get<{ success: boolean, result: { objects: T[], pageIndex: number }, count: number, error: string }>(url, { params: params });
    }

    getPageForId<T>(modelpath: string,
        pageSize: number, _id: string,
        sortOrder: string, inverse: boolean,
        ssf: { _id: string }[] | 'all' = 'all',
        filter?: string,
        getHidden: HiddenType = 'false',
        getOrganic?: 'on' | 'off' | '',
        from?: DateTime,
        to?: DateTime,
        origins?: string[] | 'notnull')
        : Observable<{ success: boolean, result: { objects: T[], pageIndex: number }, count: number, error: string }> {

        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/' + modelpath + '/page/' + _id;
        let params = new HttpParams();
        params = this.addModelOptions(params, modelpath);
        params = this.addFilterOptions(params, { pageSize, sortOrder, inverse, ssf, showHidden: getHidden, showOrganic: getOrganic, origins: { notnull: origins === 'notnull', vals: origins === 'notnull' ? [] : origins }, from, to });
        if (filter) {
            params = params.set('filter', filter);
        }
        return this.http.get<{ success: boolean, result: { objects: T[], pageIndex: number }, count: number, error: string }>(url, { params: params });
    }

    getLabelled<T>(modelpath: string, label: string, optionalParams?: { [p: string]: string} ): Observable<{ success: boolean, result: T, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/' + modelpath + '/label/' + encodeURIComponent(label);
        let params = new HttpParams();
        params = this.addModelOptions(params, modelpath);
        if (optionalParams) {
            const prms = Object.getOwnPropertyNames(optionalParams);
            for (let p = 0; p < prms.length; p++) {
                if (prms[p] && optionalParams[prms[p]]) {
                    params = params.set(prms[p], optionalParams[prms[p]]);            
                }
            }
        }
        return this.http.get<{ success: boolean, result: T, error: string }>(url, { params });
    }

    add<T>(modelpath: string, data: T, params?: { [p: string]: string } ): Observable<{ success: boolean, result: T, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/' + modelpath;
        let options = new HttpParams();
        if (params) {
            const prms = Object.getOwnPropertyNames(params);
            for (let p = 0; p < prms.length; p++) {
                if (prms[p] && params[prms[p]]) {
                    options = options.set(prms[p], params[prms[p]]);
                }
            }
        }
        return this.http.post<{ success: boolean, result: T, error: string }>(url, data, { params: options });
    }

    update<T>(modelpath: string, data: T, params?: { [p: string]: string } ): Observable<{ success: boolean, result: T, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/' + modelpath;
        let options = new HttpParams();
        if (params) {
            const prms = Object.getOwnPropertyNames(params);
            for (let p = 0; p < prms.length; p++) {
                if (prms[p] && params[prms[p]]) {
                    options = options.set(prms[p], params[prms[p]]);
                }
            }
        }
        return this.http.put<{ success: boolean, result: T, error: string }>(url, data, { params: options });
    }

    remove(modelpath: string, _id: string): Observable<{ success: boolean, result: boolean, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/' + modelpath + '/' + _id;
        return this.http.delete<{ success: boolean, result: boolean, error: string }>(url);
    }

    getTotalCoffee(): Observable<{ success: boolean, result: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/totalcoffee';
        return this.http.get<{ success: boolean, result: number, error: string }>(url);
    }

    getRegionsForOrigin(origin: string): Observable<{ success: boolean, result: string[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/regionsForOrigin';
        return this.http.get<{ success: boolean, result: string[], error: string }>(url, { params: { origin } });
    }

    getSuppliersWithPartners(country: string): Observable<{ success: boolean, result: Supplier[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/supplierswithpartners';
        return this.http.get<{ success: boolean, result: Supplier[], error: string }>(url, { params: { country } });
    }

    getCouponForSupplier(supplier: SupplierPartner): Observable<{ success: boolean, result: SupplierPartner['coupons'], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/couponforsupplier';
        return this.http.get<{ success: boolean, result: SupplierPartner['coupons'], error: string }>(url, { params: { supplierId: supplier.supplierid, partner: supplier.partner } });
    }

    getOriginsOfRoasts(): Observable<{ success: boolean, result: { origins: string[], origin_regions: string[] }, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/statistics/origins';
        return this.http.get<{ success: boolean, result: { origins: string[], origin_regions: string[] }, error: string }>(url);
    }

    getRoastFilterData(from?: DateTime, to?: DateTime): Observable<{ success: boolean, result: RoastFilterInfo, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roasts/filter/data';
        let params = new HttpParams();
        if (from?.isValid && to?.isValid) {
            params = params.set('from', from.valueOf().toString());
            params = params.set('to', to.valueOf().toString());
        }

        return this.http.get<{ success: boolean, result: RoastFilterInfo, error: string }>(url, { params });
    }

    getRoastTemplatesForFilter(from?: DateTime, to?: DateTime): Observable<{ success: boolean, result: { pre: string, num: string, label: string, id: string }[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roasts/filter/data/templates';
        let params = new HttpParams();
        if (from?.isValid && to?.isValid) {
            params = params.set('from', from.valueOf().toString());
            params = params.set('to', to.valueOf().toString());
        }

        return this.http.get<{ success: boolean, result: { pre: string, num: string, label: string, id: string }[], error: string }>(url, { params });
    }

    getRefs(model: string, objId: string): Observable<{ success: boolean, result: { count: number, refs?: unknown[] }, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/refs';
        let params = new HttpParams();
        params = params.set('model', model);
        params = params.set('objId', objId);

        return this.http.get < { success: boolean, result: { count: number, refs: unknown[] }, error: string }>(url, { params });
    }

    addSettingArray(settingName: string, settingValue: string): Observable<object> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/settingarray/' + settingName + '/' + settingValue?.toString();
        return this.http.post(url, undefined);
    }

    removeSettingArray(settingName: string, settingValue: string): Observable<object> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/settingarray/' + settingName + '/' + settingValue?.toString();
        return this.http.delete(url);
    }

    setSetting(settingName: string, settingValue?: string): Observable<object> {
        // store locally in currentUser
        const luser = localStorage.getItem('currentUser');
        if (luser) {
            const user = JSON.parse(luser) as UserType;
            if (user?.account) {
                if (!user.account.settings) {
                    if (settingValue != null && settingValue !== 'undefined') {
                        user.account.settings = { [settingName]: settingValue };
                    }
                } else {
                    if (settingValue != null && settingValue !== 'undefined') {
                        user.account.settings[settingName] = settingValue;
                    } else {
                        delete user.account.settings[settingName];
                    }
                }
                this.userService.storeCurrentUser(user);
            }
        }
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/setting/' + settingName + '/' + encodeURIComponent(settingValue?.toString());
        return this.http.post(url, undefined);
    }
}
