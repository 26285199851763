import { Component, OnInit, OnDestroy, Inject, LOCALE_ID, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, finalize, takeUntil, throttleTime } from 'rxjs';
import { InvitedUserInfo, UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';
import { RoastSchedule } from 'src/app/models/RoastSchedule';
import { RoastScheduledItem } from 'src/app/models/RoastScheduledItem';
import { SchedulerPlannerComponent } from './scheduler-planner.component';
import { IOutputAreaSizes, IOutputData } from 'angular-split';
import { SchedulerInputComponent } from './scheduler-input.component';
import { SchedulerService } from './scheduler.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SchedulerPlannerFavComponent } from './scheduler-planner-fav.component';
import { Enumerations } from 'src/app/models/Enumerations';

@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit, OnDestroy {

    constructor(
        private userService: UserService,
        private schedulerService: SchedulerService,
        public utils: Utils,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        // this.beansAndBlendsPlaceholder = `${this.tr.anslate('Beans')} / ${this.tr.anslate('Blends')}`;
    }

    readonly SHOW_PREVIOUS_DAYS = 1;
    readonly SHOW_NEXT_DAYS = 2;
    readonly MAX_SPLIT_SIZE = 500; // max height of input part in px for automatic setting
    readonly DEFAULT_SPLIT_SIZE = 279;

    lastScheduleCheck = DateTime.now().minus({days: 1});

    readonly checkTodayFun = () => {
        if (!this.today || (this.today.toISODate() !== DateTime.now().toISODate())) {
            this.today = DateTime.now();
            // browser window has been open since yesterday, reload
            this.loadSubscription.next(this.today);
        }
        if (DateTime.now().diff(this.lastScheduleCheck, 'minutes').minutes > 4) {
            this.loadSubscription.next(DateTime.fromISO(this.lastDate) ?? DateTime.now());
        }
    }

    lastDate: string;
    schedules: RoastSchedule[];

    isDataLoaded = false;
    isScheduleLoaded = false;

    defaultSplitSizes: IOutputAreaSizes = [this.DEFAULT_SPLIT_SIZE, '*'];
    splitSizes = this.defaultSplitSizes;
    readonly splitSizesLocalStorageName = 'planner-split-size';
    manualSplitSize = false;

    machineFilter: string[];
    machines: string[];

    userFilter: InvitedUserInfo[];
    users: InvitedUserInfo[];

    loading = 0;
    loadingTimer: ReturnType<typeof setTimeout>;
    currentUser: UserType;
    readOnly = false;

    DateTime = DateTime;
    today = DateTime.now();
    mainUnit: UnitSystemType = 'kg';

    loadSubscription = new Subject<string | DateTime>();
    private ngUnsubscribe = new Subject();

    @ViewChild('splitCanvas', { read: ElementRef }) canvasElementRef: ElementRef;

    @ViewChildren('schedulerPlanner') schedulerPlanners: QueryList<SchedulerPlannerComponent>;
    @ViewChildren('schedulerPlanner', { read: ElementRef }) plannerElementRefs: QueryList<ElementRef>;

    @ViewChild('schedulerInput') schedulerInput: SchedulerInputComponent;
    @ViewChild('schedulerInput', { read: ElementRef }) inputElementRef: ElementRef;

    @ViewChildren('schedulerPlannerFavorites') schedulerFavorites: QueryList<SchedulerPlannerFavComponent>;

    ngOnInit(): void {
        window.addEventListener("focus", this.checkTodayFun, false);

        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        this.readOnly = this.userService.isReadOnly();
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lb';
        }

        const localSplit = localStorage.getItem(this.splitSizesLocalStorageName);
        if (localSplit) {
            this.splitSizes = JSON.parse(localSplit);
            this.manualSplitSize = true;
        } else {
            this.splitSizes = this.defaultSplitSizes;
        }

        this.loadSubscription
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(val => {
                this.schedulerInput?.edit(undefined);
                this.lastScheduleCheck = DateTime.now();
                if (!val || typeof val === 'string') {
                    this.loadSchedule(this.lastDate);
                } else {
                    this.loadSchedule(val.toISODate());
                }
            });

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: unknown) => {
                if (e instanceof NavigationEnd && (e as NavigationEnd).url.indexOf('schedule') >= 0) {
                    // only pass and debounce interesting events
                    this.loadSubscription.next('reload');
                }
            });

        if (this.currentUser) {
            this.loadSubscription.next('init');
        }
    }

    ngOnDestroy(): void {
        window.removeEventListener('focus', this.checkTodayFun);
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.loadingTimer);
    }

    protected reloadSchedule(): void {
        this.schedulerInput?.edit(undefined);
        this.loadSubscription.next('reload');
    }

    private loadSchedule(day?: string): void {
        if (!day) {
            day = DateTime.now().toISODate();
        }
        this.lastDate = day;
        this.getRoastSchedule(day);
    }

    protected filterLabelObjects<T extends string>(search: string, objects: T[]): T[] {
        if (!objects) {
            return;
        }
        if (!search) {
            return objects;
        }
        search = search.toLocaleLowerCase(this.locale);
        return objects.filter(obj => (obj?.['label'] ?? obj)?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1);
    }

    protected filterUserObjects(search: string, objects: InvitedUserInfo[]): InvitedUserInfo[] {
        if (!objects) {
            return;
        }
        if (!search) {
            return objects;
        }
        search = search.toLocaleLowerCase(this.locale);
        return objects.filter(obj => obj.nickname?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1);
    }

    protected filterChanged(): void {
        const sps = this.schedulerPlanners?.toArray();
        for (let s = 0; s < sps?.length; s++) {
            const sp = sps[s];
            sp.setFilter(this.machineFilter, this.userFilter);
        }
    }

    private getRoastSchedule(date: string): void {
        this.loadingTimer = setTimeout(() => { this.loading += 1; }, 600);
        this.schedulerService.getSchedule(date, this.SHOW_PREVIOUS_DAYS, this.SHOW_NEXT_DAYS)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => {
                clearTimeout(this.loadingTimer);
                if (this.loading > 0) this.loading -= 1;
                this.readOnly = this.userService.isReadOnly();
            }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.schedules = response.result;
                        if (!this.schedules) {
                            this.schedules = [];
                        }
                        // // make sure schedules array has all expected entries
                        // const nrSchedules = (this.SHOW_PREVIOUS_DAYS ?? 0) + (this.SHOW_NEXT_DAYS ?? 0) + 1;
                        // const curLen = this.schedules.length;
                        // for (let i = 0; i < nrSchedules - curLen; i++) {
                        //     const sched = new RoastSchedule();
                        //     sched.date = DateTime.now().plus({ days: i - this.SHOW_PREVIOUS_DAYS + curLen }).toISODate();
                        //     this.schedules.push(sched);
                        // }
                        this.isScheduleLoaded = true;
                        if (this.isDataLoaded) {
                            this.loadDataForSchedules();
                        }
                    } else {
                        this.utils.handleError('error retrieving the schedule', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving the schedule', error);
                }
            });
    }

    /**
     * Checks if the given user _id is already in the filter. If not, returns
     * the respective InvitedUserInfo from this.users if found.
     * @param userFilter current user filter to check
     * @param userId the user _id to find
     * @returns InvitedUserInfo of the user _id if not already in the filter; undefined otherwise
     */
    private missingUserInFilter(userFilter: InvitedUserInfo[], userId: string): InvitedUserInfo {
        for (let u = 0; u < userFilter.length; u++) {
            const userInfo = userFilter[u];
            if (userInfo?._id?.toString() === userId) {
                return undefined;
            }
        }
        for (let u = 0; u < this.users.length; u++) {
            const userInfo = this.users[u];
            if (userInfo._id.toString() === userId) {
                return userInfo;
            }
        }
        return undefined;
    }

    protected onNewItem(data: { item: RoastScheduledItem, date?: string }): void {
        if (this.readOnly) { return; }

        const sps = this.schedulerPlanners?.toArray();
        const date = data?.date;

        if (this.machineFilter && data.item.machine && !this.machineFilter.includes(data.item.machine)) {
            // this.machineFilter.push(data.item.machine);
            this.machineFilter = [...this.machineFilter, data.item.machine];
        }
        if (!this.userFilter) {
            this.userFilter = [];
        }
        if (data.item.user) {
            const missingUser = this.missingUserInFilter(this.userFilter, data.item.user);
            if (missingUser) {
                // this.machineFilter.push(data.item.machine);
                this.userFilter = [...this.userFilter, missingUser];
            }
        }

        for (let s = 0; s < sps?.length; s++) {
            const sp = sps[s];
            if ((date && date === sp?.getDate()) || (!date && sp?.isMainDay)) {
                // sp.setFilter(this.machineFilter, this.userFilter);
                sp.addItem(data.item);
                break;
            }
        }
    }

    protected createTrigger(values: string[], narrow = true): string {
        if (narrow) {
            return values.map(v => v.substring(0, 6)).join(', ');
        }
        return values.join(', ');
    }

    protected createNarrowUserTrigger(users: InvitedUserInfo[]): string {
        return this.createTrigger(users.map(u => u.nickname));
    }

    protected movedAcrossDays(data: { fromDate: string; toDate: string; }) {
        if (this.readOnly) { return; }

        const sps = this.schedulerPlanners?.toArray();
        let found = 0;
        for (let s = 0; s < sps?.length; s++) {
            const sp = sps[s];
            if (data.fromDate === sp?.getDate() || data.toDate === sp?.getDate()) {
                // sp.setFilter(this.machineFilter, this.userFilter);
                this.schedulerService.addSummary(sp.schedule, sp.filteredItems, this.mainUnit);
                found += 1;
                if (found === 2) {
                    break;
                }
            }
        }
    }

    protected onDragEnd(e: IOutputData): void {
        this.splitSizes = e.sizes;
        // save user-defined size only here since gutter was explicitly dragged
        localStorage.setItem(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
        this.manualSplitSize = true;
    }

    protected onGutterClick(e: IOutputData): void {
        if (e.sizes[1] !== '*' && e.sizes[1] <= 15) {
            const height = Math.min((this.inputElementRef?.nativeElement?.offsetHeight ?? this.DEFAULT_SPLIT_SIZE) + 25);
            const totalheight = Math.max(this.canvasElementRef?.nativeElement?.offsetHeight ?? 0, 2 * height);
            const perc = Math.round(height * 100 / totalheight);
            this.splitSizes = [100 - perc, perc];
        } else {
            this.splitSizes = [85, 15];
        }
        // don't store this as user defined
        // localStorage.setItem(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
        // "store" as not user defined, i.e. set back to auto mode
        localStorage.removeItem(this.splitSizesLocalStorageName);
    }

    protected updateSplitSize(): void {
        if (!this.manualSplitSize) {
            const height = Math.min((this.inputElementRef?.nativeElement?.offsetHeight ?? this.DEFAULT_SPLIT_SIZE) + 25);
            // console.log('Height: ' + height);
            setTimeout(() => {
                const totalheight = Math.max(this.canvasElementRef?.nativeElement?.offsetHeight ?? 0, 2 * height);
                // console.log('totalheight: ' + totalheight);
                const perc = Math.round(height * 100 / totalheight);
                // console.log('perc: ' + perc);
                this.splitSizes = [100 - perc, perc];
                // localStorage.setItem(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
            }, 0);
        }
    }

    private loadDataForSchedules(): void {
        if (!this.schedulerInput) {
            setTimeout(() => {
                this.schedulerInput?.loadDataForSchedules(this.schedules);
            }, 150);
        } else {
            this.schedulerInput?.loadDataForSchedules(this.schedules);
        }

        // this (esp. the setFilter) needs to be called after the automagic
        // call of the schedule setter in the planner
        setTimeout(() => {
            const sps = this.schedulerPlanners?.toArray();
            for (let s = 0; s < sps?.length; s++) {
                this.schedulerService.addInfoAndSummary(sps?.[s]?.schedule, sps?.[s]?.filteredItems, this.mainUnit);
                sps?.[s]?.setFilter(this.machineFilter, this.userFilter);
            }
        }, 150);
        // this.isDataLoaded = false;
        this.isScheduleLoaded = false;
    }

    protected dataLoaded(): void {
        this.isDataLoaded = true;
        if (this.isScheduleLoaded) {
            this.loadDataForSchedules();
        }
    }

    protected onCancelEdit(): void {
        const sps = this.schedulerPlanners?.toArray();
        for (let s = 0; s < sps?.length; s++) {
            sps[s]?.deselect();
        }
    }

    protected onSaveEdit(event: { item: RoastScheduledItem, cb: (success: boolean) => void, favoritesLine?: number }): void {
        if (this.readOnly) { return; }

        const editModeItemOriginal = event?.item;
        let success = false;
        if (event.favoritesLine >= 0) {
            const sfs = this.schedulerFavorites?.toArray();
            // success = sfs.some(sf => sf.updateEditedItem(/*editModeItemOriginal*/));
            success = sfs[event.favoritesLine].updateEditedItem(/*editModeItemOriginal*/);
        } else {
            const sps = this.schedulerPlanners?.toArray();
            success = sps.some(sp => sp.updateEditedItem(editModeItemOriginal));
        }
        // let success = false;
        // for (let s = 0; s < sps?.length; s++) {
        //     success = success || sps[s]?.updateEditedItem(editModeItemOriginal);
        // }
        if (typeof event.cb === 'function') {
            event.cb(success);
        }
    }

    protected editItem(itemInfo: { item: RoastScheduledItem, readonly?: boolean, favoritesLine?: number }, schIdx: number): void {
        this.schedulerInput?.edit(itemInfo.item, itemInfo.readonly, itemInfo.favoritesLine);

        // deselect all others
        const sps = this.schedulerPlanners?.toArray();
        for (let s = 0; s < sps?.length; s++) {
            if (s !== schIdx) {
                sps[s]?.deselect(false);
            }
        }
        const sfs = this.schedulerFavorites?.toArray();
        for (let s = 0; s < sfs?.length; s++) {
            if (s !== itemInfo.favoritesLine) {
                sfs[s]?.deselect();
            }
        }
    }

    protected updateFinished(): void {
        this.schedulerInput?.updateFinished();
    }

    protected machinesLoaded(machines: string[]): void {
        this.machines = machines ?? [];
        // this.machineFilter = this.machines?.slice();
        this.machineFilter = undefined;
    }

    protected usersLoaded(users: InvitedUserInfo[]): void {
        this.users = users ?? [];
        this.userFilter = this.users?.slice();
    }

    protected addCurrentItem(date: string): void {
        if (this.readOnly) { return; }

        // ensure input component is visible
        if (this.splitSizes[1] !== '*' && this.splitSizes[1] <= 25) {
            const height = Math.min((this.inputElementRef?.nativeElement?.offsetHeight || this.DEFAULT_SPLIT_SIZE) + 25, this.DEFAULT_SPLIT_SIZE + 25);
            const totalheight = Math.max(this.canvasElementRef?.nativeElement?.offsetHeight || 0, 2 * height);
            const perc = Math.round(height * 100 / totalheight);
            this.splitSizes = [100 - perc, perc];
        }

        // deselect all others
        const sps = this.schedulerPlanners?.toArray();
        for (let s = 0; s < sps?.length; s++) {
            const sp = sps[s];
            if (sp.getDate() !== date) {
                sp?.deselect();
            } else {
                // ensure new item is visible
                this.plannerElementRefs.get(s).nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        }
        // this will, if successful, select the new item
        this.schedulerInput?.addCurrent(date);
    }

    protected scrollTo(date: DateTime) {
        this.loadSubscription.next(date);
        // this.loadSchedule(date.toISODate());
    }

    // protected scrollTo(info: 'start' | 'end' | DateTime) {
    //     if (info === 'start') {
    //         const today = DateTime.now().toISODate();
    //         if (this.lastDate > today) {
    //             // go to today
    //             this.loadSchedule(today);
    //         } else {
    //             // TODO ask server for a sensible previous date
    //             // e.g. last with unfinished roasts; first of month etc.
    //             throw('not implemented');
    //         }
    //     } else if (info === 'end') {
    //         // TODO ask server for a sensible previous date
    //         // e.g. last with unfinished roasts; first of month etc.
    //         throw ('not implemented');
    //     } else {
    //         this.loadSchedule(info.toISODate());
    //     }
    // }

    protected scrollDay(dir: -1 | 0 | 1 | MatDatepickerInputEvent<DateTime>) {
        // this.edit(this.lastEditItem, true, true);
        if (dir === 0) {
            this.scrollTo(DateTime.now());
            return;
        } else if (dir === -1 || dir === 1) {
            const day = DateTime.fromISO(this.lastDate);
            const otherDay = day.plus({ day: dir });
            this.scrollTo(otherDay);
            return;
        }
        if (dir?.value?.isValid) {
            this.scrollTo(dir.value);
            return;
        }
        this.scrollTo(DateTime.now());
    }

    // protected scrollMax(dir: -1 | 1) {
    //     // this.edit(this.lastEditItem, true, true);
    //     this.scrollTo(dir === -1 ? 'start' : 'end');
    // }
}
