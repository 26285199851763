export const EMPTYTAG = '--';

export function convertFromIndexToValue(modelValue: number, stepsArray: { value: number }[]): number {
    try {
        const step = stepsArray[modelValue];
        if (step != null) {
            if (modelValue === 0 || modelValue === stepsArray.length - 1) {
                return step.value;
            }
            return step.value ? (Math.round(step.value * 1000) / 1000) : step.value;
        }
        const preIdx = Math.floor(modelValue);
        const postIdx = Math.min(preIdx + 1, stepsArray.length - 1);
        const stepSize = stepsArray[postIdx].value - stepsArray[preIdx].value;
        const convResult = stepsArray[preIdx].value + (modelValue % 1) * stepSize;
        return convResult ? (Math.round(convResult * 1000) / 1000) : step.value;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
        return modelValue;
    }
}

function findStepIndices(modelValue: number, stepsArray: { value: number }[]): { preIdx: number, postIdx: number } {
    const differences = stepsArray.map(step => Math.abs(modelValue - step.value));
    let minDifferenceIndex = 0;
    for (let index = 0; index < stepsArray.length; index++) {
        if (differences[index] !== differences[minDifferenceIndex] && differences[index] < differences[minDifferenceIndex]) {
            minDifferenceIndex = index;
        }
    }
    if (modelValue < stepsArray[minDifferenceIndex].value) {
        return { preIdx: minDifferenceIndex - 1, postIdx: minDifferenceIndex };
    }
    return { preIdx: minDifferenceIndex, postIdx: Math.min(minDifferenceIndex + 1, stepsArray.length - 1) };
}

export function convertFromValueToIndex(value: number, stepsArray: { value: number }[]): number {
    let { preIdx, postIdx } = findStepIndices(value, stepsArray);
    if (preIdx < 0) {
        preIdx = 0;
    }
    if (postIdx > stepsArray.length - 1) {
        postIdx = stepsArray.length - 1;
    }
    if (preIdx === postIdx) {
        return preIdx;
    }
    const convResult = (value - stepsArray[preIdx].value) / (stepsArray[postIdx].value - stepsArray[preIdx].value) + preIdx;
    // const convResult = value - stepsArray[preIdx].value + preIdx;
    return convResult;
}
