// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  margin-top: 10px;
}

.recurring-interval-container {
  display: flex;
  align-items: flex-end;
}

.recurring-interval {
  min-width: 50px;
  margin-left: 10px;
  max-width: 50px;
}

.recurring-interval-input {
  text-align: right;
}

.recurring-freq {
  margin-left: 10px;
}

.recurring-count {
  width: 50px;
}

.recurring-count-input {
  text-align: right;
}

.recurring-count-label {
  margin-left: 10px;
}

.recurring-weekday-container {
  text-align: end;
}

.recurring-end-radio-container {
  display: flex;
  margin-top: -10px;
  margin-bottom: 10px;
}

.recurring-end-radio-group,
.recurring-end-details {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 85px;
  max-width: 224px;
}

.recurring-end-radio-group {
  margin-right: 20px;
  margin-top: 10px;
}

.recurring-end-radio-buton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.recurring-end-datepicker {
  display: flex;
}

.recurring-dates {
  height: 95px;
  overflow: scroll;
  font-size: 14px;
  line-height: 20px;
}

.recurring-dates-label {
  margin-bottom: 5px;
}

.recurring-dates-icon {
  margin-right: 5px;
  cursor: pointer;
  margin-left: 0;
  vertical-align: top;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
  width: 16px;
  text-indent: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
