import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-generate-error-found',
    templateUrl: './generate-error.component.html',
})
export class GenerateErrorComponent implements OnInit {

    ngOnInit(): void {
        // @ts-expect-error this is used to explicitly generate an error for testing the error framework
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        explicitlyGeneratedError.explicitlyGeneratedError;
    }

}
