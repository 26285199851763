import { User } from 'src/app/models/User';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FilterOptions, GetPageOptions, StandardService } from 'src/app/util/services/standard.service';
import { Utils } from 'src/app/util/utils';
import { ObjectChangedInfo, ObjectChangedService } from 'src/app/util/services/objectchanged.service';
import { Observable, Subject, of, timer } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Roast } from 'src/app/models/Roast';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { throttleTime, takeUntil, switchMap, debounce } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Coffee } from 'src/app/models/Coffee';
// import { RoastGraphsComponent } from 'src/app/modules/graph/roast/roast-graphs.component';
import { Location } from 'src/app/models/Location';
import { Enumerations } from 'src/app/models/Enumerations';
import { BleAcaia } from './ble-acaia';
import { RemindersService } from '../reminders/reminder.service';
import { IOutputData } from 'angular-split';
import { PageEvent } from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';
import { RoastsFilterComponent } from './roasts-filter.component';
import { Constants } from 'src/app/util/constants';
import cloneDeep from 'lodash-es/cloneDeep';
import { IdAndLabelArrayOrNotnull } from './filters/filter.component';
import { Utils2 } from 'src/app/util/utils2';


@Component({
    selector: 'app-roasts',
    templateUrl: './roasts.component.html',
    styleUrls: ['./roasts.component.scss'],
    providers: []
})
export class RoastsComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private standardService: StandardService,
        private userService: UserService,
        private objectChangedService: ObjectChangedService,
        public utils: Utils,
        private utils2: Utils2,
        public tr: TranslatorService,
        private bleAcaia: BleAcaia,
        private remindersService: RemindersService,
    ) { }

    readonly FILTER_DEBOUNCE_MS = 750;

    defaultSplitSizes = [70, 30];
    splitSizes = this.defaultSplitSizes;
    readonly splitSizesLocalStorageName = 'roast-graph-split-size';

    filteredObjects: Roast[];
    filteredObjectCount = 0;
    maxObjectCount = -1;
    pageSize = 10;
    pageIndex = 0;
    editNewRoast = -1;
    isNew = -1;
    idToHighlight: string;
    stores: { _id: string, hr_id?: string, label?: string }[];
    // showstockfrom: { _id: string, hr_id?: string, label?: string }[] | 'all' = 'all';

    sortValue: string;
    lastSortValue: string;
    firstSort = true;
    inverse = false;
    moreThanOneUser = false;

    gettingForFilterLongRunning = false;
    gettingForFilter = false;
    gettingForFilterTimer: ReturnType<typeof setTimeout>;

    currentFilter: GetPageOptions = {};

    machines: { label: string, cnt: number }[];

    currentUser: UserType;
    readOnly = false;
    readOnlyAccount = false;
    isDarkmode = false;

    energyUnit = 'BTU';

    // // need to use a setter, otherwise, due to the *ngIf, it will always be undefined
    // // https://stackoverflow.com/questions/39366981/angular-2-viewchild-in-ngif
    // _graphsComponent: RoastGraphsComponent;
    // @ViewChild(RoastGraphsComponent) set graphsComponent(gc: RoastGraphsComponent) {
    //     this._graphsComponent = gc;
    // }
    @ViewChild(RoastsFilterComponent) roastFilterComponent: RoastsFilterComponent;

    paging$ = new Subject<PageEvent>();
    filtering$ = new Subject<{ options?: GetPageOptions, sortOnly?: boolean, now?: boolean }>();

    loadSubscription = new Subject<string>();
    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();


    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        if (localStorage.getItem(this.splitSizesLocalStorageName)) {
            this.splitSizes = JSON.parse(localStorage.getItem(this.splitSizesLocalStorageName));
        } else {
            this.splitSizes = this.defaultSplitSizes;
        }

        this.pageSize = this.userService.getPageSize('roasts', this.pageSize);
        this.energyUnit = this.currentUser.energy_unit || 'BTU';

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm)
            );

        this.objectChangedService.changed$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                obj => this.objectChanged(obj)
            );

        this.loadSubscription
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.sortValue = 'roastdate';
                this.lastSortValue = 'roastdate';

                this.filteredObjects = undefined;
                this.filteredObjectCount = 0;
                this.maxObjectCount = -1;
                this.editNewRoast = -1;
                this.isNew = -1;
                this.pageIndex = 0;

                const ssf = localStorage.getItem('stockfrom_' + this.currentUser.user_id);
                if (ssf && ssf !== 'all') {
                    this.currentFilter.ssf = ssf.split(Constants.SSF_SEPARATOR).map(s => ({ _id: s }));
                }

                this.idToHighlight = this.route.snapshot.params.id;
                if (this.idToHighlight) {
                    // have a direct link; need to get the corresponding page
                    this.getPageForId(this.idToHighlight);
                } else {
                    this.filtering$.next({ options: undefined, now: true });
                    // this.getPage();
                }
            }
            );

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: unknown) => {
                if (e instanceof NavigationEnd && (e as NavigationEnd).url.indexOf('roasts') >= 0) {
                    // only pass and debounce interesting events
                    this.resetFilters();
                    this.loadSubscription.next('reload');
                }
            });

        this.paging$.pipe(
            takeUntil(this.ngUnsubscribe),
            switchMap($event => this.pagingChanged($event))
        ).subscribe(this.handlePageResponse());

        this.filtering$.pipe(
            takeUntil(this.ngUnsubscribe),
            // debounceTime(this.filterDebounceTime),
            debounce(ev => ev?.now ? of({}) : timer(this.FILTER_DEBOUNCE_MS)),
            // debounce(() => race(timer(1250), this.cancelFilterChange)),
            switchMap($event => this.filterObjects($event?.options, $event.sortOnly)),
        ).subscribe(this.handlePageResponse());

        this.sortValue = 'roastdate';
        this.lastSortValue = 'roastdate';

        if (this.currentUser) {
            this.loadSubscription.next('init');
        }

        // preload all stores and machines for editing
        this.getAllStores();
        this.getAllMachines();
    }

    async ngOnDestroy(): Promise<void> {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        if (this.bleAcaia) {
            await this.bleAcaia.disconnect();
        }
        clearTimeout(this.gettingForFilterTimer);
    }

    onDragEnd(e: IOutputData): void {
        this.splitSizes = e.sizes as number[];
        localStorage.setItem(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
    }

    onGutterClick(e: IOutputData): void {
        if (e.sizes[0] === '*' || e.sizes[0] < 50) {
            this.splitSizes = [100, 0];
        } else {
            this.splitSizes = [0, 100];
        }
        localStorage.setItem(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
    }

    reloadGraphData(): void {
        // if (this._graphsComponent) {
        //     this._graphsComponent.reloadData();
        // }
    }

    getAllStores(): void {
        this.standardService.getAll<Location>('stores')
            // TODO .pipe(finalize(() => /* duplicate code block */))
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stores = (response.result as Location[]).map(s => {
                            return { _id: s._id, hr_id: s.hr_id, label: s.label }
                        });
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                        if (!this.stores?.length) {
                            this.stores = [];
                        }
                    }
                    this.stores.sort((s1, s2) => s1.hr_id > s2.hr_id ? -1 : s1.hr_id > s2.hr_id ? 1 : 0);
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                    if (!this.stores?.length) {
                        this.stores = [];
                    }
                }
            });
    }

    getAllMachines(): void {
        this.remindersService.getAllMachines()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.machines = response.result;
                    } else {
                        this.utils.handleError('error retrieving all machines', response.error);
                        if (!this.machines?.length) {
                            this.machines = [];
                        }
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all machines', error);
                    if (!this.machines?.length) {
                        this.machines = [];
                    }
                }
            });
    }

    // called from outside when filter changes in other (e.g. graph) component
    tellFilterChanged(newOptions: FilterOptions = {}): void {
        Object.assign(this.currentFilter, newOptions);
        this.filtering$.next({ options: this.currentFilter });
    }

    getDefaultOptions(): GetPageOptions {
        return {
            pageSize: this.userService.getPageSize('roasts', this.pageSize),
            pageIndex: 0,
        };
    }

    resetFilters(): void {
        this.pageSize = this.userService.getPageSize('roasts', this.pageSize);
        this.pageIndex = 0;
        this.sortValue = undefined;
        this.lastSortValue = undefined;
        this.firstSort = true;
        this.inverse = false;

        this.currentFilter = { pageSize: this.pageSize, pageIndex: this.pageIndex };

        // this.showstockfrom = this.utils.readShowStockFrom(this.stores, this.currentUser);
        // this.origins = [];
        // this.showOrganic = '';
        // this.filterStartDate = undefined;
        // this.filterEndDate = undefined;
    }

    filterObjects(newOptions: GetPageOptions = {}, sortOnly = false): Observable<{ success: boolean; result: Roast[]; count: number; error: string; }> {
        newOptions.pageIndex ??= 0;
        this.pageIndex = newOptions.pageIndex;
        // if (sortOnly) {
        //     this.currentFilter = Object.assign(this.currentFilter ?? {}, newOptions);
        // } else {
        //     this.currentFilter = Object.assign(this.currentFilter ?? {}, this.getDefaultOptions(), newOptions);
        // }

        this.currentFilter = cloneDeep(this.currentFilter);
        Object.assign(this.currentFilter, newOptions);

        this.utils2.cleanResult(this.currentFilter);
        // console.log('now filtering!', sortOnly, this.currentFilter);

        if ((this.currentFilter.pageIndex || 0) === (this.pageIndex || 0)) {
            // filter those that are currently displayed / in the cache
            // doesn't make sense if page index changed
            this.localFilterAndSort(this.currentFilter, sortOnly);
        }

        // if (!sortOnly && this._graphsComponent) {
        //     this._graphsComponent.tellFilterChanged(cloneDeep(this.currentFilter));
        // }

        // only ask server if not all objects are displayed in the current page
        if (this.maxObjectCount < 0 || this.filteredObjects?.length !== this.maxObjectCount) {
            return this.getPage(this.currentFilter);
        }
        return of({ success: true, result: this.filteredObjects, count: this.filteredObjects?.length, error: '' });
    }

    private organicRoastFilter(showOrganic: "" | "on" | "off", roast: Roast): boolean {
        if (showOrganic === 'on') {
            if (!(roast.certInfo & Enumerations.CertificationTypes.ORGANIC)) {
                return false;
            }
        } else if (showOrganic === 'off') {
            if (roast.certInfo & Enumerations.CertificationTypes.ORGANIC) {
                return false;
            }
        }
        return true;
    }

    /**
     * Filters the current filteredObjects according to the given filters.
     * @param {GetPageOptions} options filters
     * @see addDefaultsToFilterOptions
     */
    private localFilterAndSort(options: GetPageOptions = {}, sortOnly = false) {
        // this.addDefaultsToFilterOptions(options);

        if (sortOnly) {
            if (this.lastSortValue !== options.sortOrder || this.inverse !== options.inverse) {
                this.clientSideSort(options, this.filteredObjects);
            }
            return;
        }

        if (options.ssf && !options.ssf?.length) {
            this.filteredObjects = [];
            this.filteredObjectCount = 0;
            return;
        }
        let ssfids: string[];
        if (options.ssf && options.ssf !== 'all') {
            ssfids = options.ssf.map(s => s._id);
        }

        // TODO this currently filters only the main attributes!
        // should optimize for the cases "no filter" or "filter not changed"
        // currently, we assume that this is only called if sth has changed
        this.filteredObjects = (this.filteredObjects ?? []).filter(roast => {
            if (options.from?.isValid && options.to?.isValid) {
                const rdate = roast.date;
                if (rdate < options.from || rdate > options.to) {
                    return false;
                }
            }
            if (options.label && !RegExp(options.label, 'i').test(roast.label ?? '')) {
                return false;
            }
            if (options.showOrganic) {
                if (!this.organicRoastFilter(options.showOrganic, roast)) {
                    return false;
                }
            }
            if (options.origins?.notnull) {
                if ((roast.coffee && !roast.coffee.origin)
                    || (roast.blend && !this.blendMatchesOrigin(roast.blend, options.origins))) {
                    return false;
                }
            } else if (options.origins?.vals?.length) {
                if (!roast.coffee && !roast.blend
                    || (roast.coffee && (!roast.coffee.origin || (options.origins.vals.indexOf(roast.coffee.origin) < 0 && options.origins.vals.indexOf(roast.coffee.origin_region) < 0)))
                    || (roast.blend && !this.blendMatchesOrigin(roast.blend, options.origins))) {
                    return false;
                }
            }
            if (ssfids) {
                if (!roast.location || ssfids.indexOf((roast.location._id || roast.location).toString()) < 0) {
                    return false;
                }
            }
            if (options.coffees && options.blends) {
                let coffeeresult = true;
                if (options.coffees.notnull) {
                    if (!roast.coffee) {
                        coffeeresult = false;
                    }
                } else if (!options.coffees.vals?.map(c => (c?.['label'] ?? c)?.toString() ?? null).includes(roast.coffee?._id?.toString() ?? null)) {
                    coffeeresult = false;
                }
                let blendsresult = true;
                if (!coffeeresult) {
                    if (options.blends.notnull) {
                        if (!roast.blend) {
                            blendsresult = false;
                        }
                    } else if (!options.blends.vals?.map(b => (b?.['label'] ?? b)?.toString() ?? null).includes(roast.blend?.label ?? null)) {
                        blendsresult = false;
                    }
                }
                if (!coffeeresult && !blendsresult) {
                    return false;
                }
                
                // if (!options.blends.map(b => (b?.label ?? b)?.toString() ?? null).includes(roast.blend?.label ?? null)
                //     && !options.coffees.map(c => (c?.label ?? c)?.toString() ?? null).includes(roast.coffee?._id?.toString() ?? null)) {
                //     return false;
                // }
            } else {
                if (options.coffees) {
                    if (options.coffees.notnull) {
                        if (!roast.coffee) {
                            return false;
                        }
                    } else if (!options.coffees.vals?.map(c => (c?.['label'] ?? c)?.toString() ?? null).includes(roast.coffee?._id?.toString() ?? null)) {
                        return false;
                    }
                }
                if (options.blends) {
                    if (options.blends.notnull) {
                        if (!roast.blend) {
                            return false;
                        }
                    } else if (!options.blends.vals?.map(b => (b?.['label'] ?? b)?.toString() ?? null).includes(roast.blend?.label ?? null)) {
                        return false;
                    }
                }
            }
            return true;
        });
        this.filteredObjectCount = this.filteredObjects?.length || 0;

        // this.filteredObjects = this.filteredObjects.slice(options.pageSize * options.pageIndex, options.pageSize * (options.pageIndex + 1));
    }

    // private filterByWeight(filterNum: number, capFilter: string, toCheck: number): boolean {
    //     if (filterNum === 0 && (capFilter.indexOf('.') >= 0 || capFilter.indexOf(',') >= 0)) {
    //         return !toCheck;
    //     }

    //     const convFilterNum = this.utils.convertToKg(filterNum, this.currentUser?.unit_system);
    //     if (capFilter.substring(0, 1) === '>') {
    //         return toCheck > convFilterNum;
    //     }
    //     if (capFilter.substring(0, 1) === '<') {
    //         return toCheck < convFilterNum;
    //     }
    //     if (Math.round(filterNum) === filterNum && capFilter.indexOf('.') < 0 && capFilter.indexOf(',') < 0) {
    //         // integer number: get all with equal _rounded_ amount
    //         return Math.round(this.utils.convertToUserUnit(toCheck, this.currentUser?.unit_system)) === filterNum;
    //     }
    //     // float number: get all with equal amount rounded to same number of digits
    //     // const endNum = capFilter.search(/[^-,.\d]/);
    //     let svall = capFilter.replace(/,/g, '.');
    //     svall = svall.replace(/[^-\d.]/g, '');
    //     const digits = svall.length - svall.indexOf('.') - 1;
    //     // const numstr = svall.substring(0, endNum < 0 ? capFilter.length : endNum);
    //     const cToCheck = this.utils.convertToUserUnit(toCheck, this.currentUser?.unit_system);
    //     return cToCheck && cToCheck.toFixed(digits) === svall;
    // }

    // private filterByNumber(roast: Roast, filterNum: number, capFilter: string): boolean {
    //     if (filterNum === 0 && (capFilter.indexOf('.') >= 0 || capFilter.indexOf(',') >= 0)) {
    //         return !roast.amount || !roast.end_weight;
    //     }
    //     // need to do this before converting the number according to the user's unit_system
    //     if (roast.batch_number === filterNum || roast.internal_hr_id === filterNum) {
    //         return true;
    //     }
    //     if (Math.round(filterNum) === filterNum && capFilter.indexOf('.') < 0 && capFilter.indexOf(',') < 0) {
    //         // integer number: get all with equal _rounded_ amount or end_weight, or batch number, or hr_id
    //         return (Math.round(this.utils.convertToUserUnit(roast.amount, this.currentUser?.unit_system)) === filterNum)
    //             || (Math.round(this.utils.convertToUserUnit(roast.end_weight, this.currentUser?.unit_system)) === filterNum);
    //     }
    //     // float number: get all with equal amount or end_weight rounded to same number of digits
    //     // const endNum = capFilter.search(/[^-,.\d]/);
    //     let svall = capFilter.replace(/,/g, '.');
    //     svall = svall.replace(/[^-\d.]/g, '');
    //     const digits = svall.length - svall.indexOf('.') - 1;
    //     // const numstr = svall.substring(0, endNum < 0 ? capFilter.length : endNum);
    //     const cAmount = this.utils.convertToUserUnit(roast.amount, this.currentUser?.unit_system);
    //     const cyield = this.utils.convertToUserUnit(roast.end_weight, this.currentUser?.unit_system);
    //     return (cAmount && cAmount.toFixed(digits) === svall) || (cyield && cyield.toFixed(digits) === svall);
    // }

    // private blendMatches(blend: { label?: string, ingredients?: { coffee: Coffee, ratio: number }[] }, theFilter: string, onlyOrigin = false): boolean {
    //     if (!onlyOrigin && blend.label?.toUpperCase()?.indexOf(theFilter) >= 0) {
    //         return true;
    //     }
    //     if (!blend.ingredients) {
    //         return false;
    //     }
    //     for (let b = 0; b < blend.ingredients.length; b++) {
    //         if (blend.ingredients[b].coffee &&
    //             (!onlyOrigin && blend.ingredients[b]?.coffee?.label?.toUpperCase().indexOf(theFilter) >= 0) ||
    //             (!onlyOrigin && blend.ingredients[b]?.coffee?.hr_id?.toUpperCase() === theFilter) ||
    //             (blend.ingredients[b]?.coffee?.origin && this.tr.anslate(blend.ingredients[b].coffee.origin)?.toUpperCase().indexOf(theFilter) >= 0)) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    // if any ingredient coffee has an origin contained in origins, return true
    private blendMatchesOrigin(blend: { ingredients?: { coffee: Coffee }[] }, origins: IdAndLabelArrayOrNotnull<string>): boolean {
        if (origins.notnull) {
            if (!blend.ingredients) {
                return false;
            }
            for (let b = 0; b < blend.ingredients.length; b++) {
                if (blend.ingredients[b].coffee && !blend.ingredients[b].coffee.origin) {
                    return false;
                }
            }
            return true;
        }
        if (!blend.ingredients) {
            return false;
        }
        for (let b = 0; b < blend.ingredients.length; b++) {
            if (origins.vals?.indexOf(blend.ingredients[b].coffee?.origin) >= 0
                || origins.vals?.indexOf(blend.ingredients[b].coffee?.origin_region) >= 0) {
                return true;
            }
        }
        return false;
    }

    // private blendMatchesCoffee(blend: { label?: string, ingredients?: { coffee: Coffee, ratio: number }[] }, theFilter: string): boolean {
    //     if (!blend.ingredients) {
    //         return false;
    //     }
    //     for (let b = 0; b < blend.ingredients.length; b++) {
    //         if (blend.ingredients[b].coffee &&
    //             (blend.ingredients[b]?.coffee?.label?.toUpperCase().indexOf(theFilter) >= 0) ||
    //             (blend.ingredients[b]?.coffee?.hr_id?.toUpperCase() === theFilter)) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    // private blendMatchesFlavor(blend: { ingredients?: { coffee: Coffee }[] }, theFilter: string): boolean {
    //     if (!blend.ingredients) {
    //         return false;
    //     }
    //     for (let b = 0; b < blend.ingredients.length; b++) {
    //         if (blend.ingredients[b].coffee) {
    //             const coff = blend.ingredients[b].coffee;
    //             if (!coff?.flavors?.length) {
    //                 return false;
    //             }
    //             for (let f = 0; f < coff.flavors.length; f++) {
    //                 const flavor = coff.flavors[f]?.trim()?.toUpperCase();
    //                 if (flavor === theFilter) {
    //                     return true;
    //                 }
    //             }
    //         }
    //     }
    //     return false;
    // }

    /**
     * Handles result of filterObjects call.
     * @param  callback function called when finished (sucess or error)
     */
    private handlePageResponse() {
        return {
            next: response => {
                this.readOnly = this.userService.isReadOnly();
                this.readOnlyAccount = this.userService.isReadOnlyAccount();
                clearTimeout(this.gettingForFilterTimer);
                setTimeout(() => {
                    this.gettingForFilterLongRunning = false;
                }, 600);
                this.gettingForFilter = false;
                this.roastFilterComponent?.setIsLoading(false);
                if (response.success === true) {
                    this.maxObjectCount = Math.max(response.count || 0, this.maxObjectCount);
                    this.filteredObjects = this.utils.dateifyRoasts(response.result);
                    this.filteredObjectCount = response.count;
                } else {
                    this.utils.handleError('error retrieving all roasts', response.error);
                }
            },
            error: (error: HttpErrorResponse) => {
                this.utils.handleError('error retrieving all roasts', error);
                clearTimeout(this.gettingForFilterTimer);
                setTimeout(() => {
                    this.gettingForFilterLongRunning = false;
                }, 600);
                this.gettingForFilter = false;
                this.roastFilterComponent?.setIsLoading(false);

                // resubscribe since the stream is completed as soon as an error occurs
                this.filtering$.unsubscribe();
                this.filtering$ = new Subject<{ options?: GetPageOptions, sortOnly?: boolean, now?: boolean }>();
                this.filtering$.pipe(
                    takeUntil(this.ngUnsubscribe),
                    // debounceTime(this.filterDebounceTime),
                    debounce(ev => ev?.now ? of({}) : timer(this.FILTER_DEBOUNCE_MS)),
                    // debounce(() => race(timer(1250), this.cancelFilterChange)),
                    switchMap($event => this.filterObjects($event?.options)),
                ).subscribe(this.handlePageResponse());
            }
        }
    }

    /**
     * Gets the current (or given) page of objects.
     * It uses the given filter / sort options or the component's current values.
     * If any filter / sort options change, filterObjects
     * should be called which filters/sorts locally and potentially calls this getPage
     * method to decide whether it is necessary to retrieve data from the server.
     * @param pageSize 
     * @param pageIndex 
     * @param sortValue 
     * @param inverse 
     * @param date.from
     * @param date.to
     * @param ssf showStockFrom, acts as a location filter (not checked if changed, need to call filterRoasts explicitly)
     * @param showOrganic whether to show only organic ('on'), only non-organic ('off'), or any ('')
     * @param origins set of (English) origins/origin_regions to filter
     * @see filterObjects
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getPage(newOptions?: GetPageOptions): Observable<{ success: boolean; result: Roast[]; count: number; error: string }> {
        // Object.assign(this.currentFilter, newOptions || {});
        // this.currentFilter = newOptions;
        this.currentFilter = Object.assign({}, this.getDefaultOptions(), newOptions);
        // const options = Object.assign({}, this.getDefaultOptions(), this.currentFilter);
        // this.addDefaultsToFilterOptions(options);

        this.roastFilterComponent?.setIsLoading(true);
        this.gettingForFilter = true;
        this.gettingForFilterTimer = setTimeout(() => {
            this.gettingForFilterLongRunning = true;
        }, 600);

        return this.standardService.getPage2<Roast>('roasts', this.currentFilter);
        // return this.standardService.getPage2<Roast>('roasts', options);
            // .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            // .subscribe(this.handlePageResponse(done));
    }

    getPageForId(id: string, newOptions: GetPageOptions = {}): void {
        const options = Object.assign({}, this.getDefaultOptions(), this.currentFilter, newOptions || {});
        // this.addDefaultsToFilterOptions(options);

        // check if on current page first
        let idLabel = '_id';
        if (id[0] === 'R') {
            idLabel = 'hr_id';
        }
        let index = -1;
        for (let i = 0; i < this.filteredObjects?.length; i++) {
            const obj = this.filteredObjects[i] as Roast;
            if (obj[idLabel] === id || obj.roast_id === id) {
                index = i;
                break;
            }
        }
        if (index >= 0) {
            return;
        } else {
            this.gettingForFilter = true;
            this.gettingForFilterTimer = setTimeout(() => {
                this.gettingForFilterLongRunning = true;
            }, 600);

            // need to get the corresponding page from the server
            this.standardService.getPageForId2<Roast>('roasts', this.pageSize, id, options)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        this.readOnly = this.userService.isReadOnly();
                        this.readOnlyAccount = this.userService.isReadOnlyAccount();
                        clearTimeout(this.gettingForFilterTimer);
                        setTimeout(() => {
                            this.gettingForFilterLongRunning = false;
                        }, 600);
                        this.gettingForFilter = false;
                        if (response.success === true) {
                            this.maxObjectCount = Math.max(response.count || 0, this.maxObjectCount);
                            this.filteredObjects = this.utils.dateifyRoasts(response.result.objects);
                            if (!this.filteredObjects?.length) {
                                // object with given id not found
                            } else {
                                this.pageIndex = response.result.pageIndex;
                                this.filteredObjectCount = response.count;
                            }
                        } else {
                            this.utils.handleError('error retrieving all roasts', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving all roasts', error);
                        clearTimeout(this.gettingForFilterTimer);
                        setTimeout(() => {
                            this.gettingForFilterLongRunning = false;
                        }, 600);
                        this.gettingForFilter = false;
                    }
                });
        }
    }

    private pagingChanged($event: PageEvent) {
        // directly use getPage to be able to use switchMap to cancel pending requests
        const newOptions = { pageIndex: $event.pageIndex, pageSize: $event.pageSize };
        this.currentFilter = Object.assign({}, this.getDefaultOptions(), this.currentFilter, newOptions || {});
        // this.addDefaultsToFilterOptions(options);

        this.gettingForFilter = true;
        this.gettingForFilterTimer = setTimeout(() => {
            this.gettingForFilterLongRunning = true;
        }, 600);

        if (this.pageSize !== $event.pageSize) {
            this.pageSize = $event.pageSize;
            this.userService.setPageSize('roasts', this.pageSize);
        }
        this.pageIndex = $event.pageIndex;
        return this.standardService.getPage2<Roast>('roasts', this.currentFilter);
    }
    // pagingChanged($event: PageEvent): void {
    //     this.getPage({ pageIndex: $event.pageIndex, pageSize: $event.pageSize });
    //     if (this.pageSize !== $event.pageSize) {
    //         this.pageSize = $event.pageSize;
    //         this.userService.setPageSize('roasts', this.pageSize);
    //     }
    //     this.pageIndex = $event.pageIndex;
    // }

    /**
     * Sorts given roasts array in place.
     * @param sortValue e.g. 'roastdate'
     * @param inverse whether to reverse the sort order
     */
    clientSideSort(options: GetPageOptions, roastsToSort: Roast[]): void {
        const inversenum = options.inverse ? 2 : 0;
        roastsToSort.sort((r1, r2) => {
            let diff = 0;
            switch (options.sortOrder) {
                case undefined:
                case 'roastdate':
                default:
                    if (!r1.date && !r2.date) { return 0; }
                    if (!r2.date) { return 1 - inversenum; }
                    if (!r1.date) { return -1 + inversenum; }
                    diff = +r1.date - +r2.date;
                    if (diff < 0) { return 1 - inversenum; }
                    if (diff > 0) { return -1 + inversenum; }
                    diff = r1.internal_hr_id - r2.internal_hr_id;
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);

                // case 'hr_id':
                //     diff = r1.internal_hr_id - r2.internal_hr_id;
                //     return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);

                case 'label':
                    if (!r1.label) { r1.label = ''; }
                    if (!r2.label) { r2.label = ''; }
                    if (r1.label.toUpperCase() < r2.label.toUpperCase()) { return -1 + inversenum; }
                    if (r1.label.toUpperCase() > r2.label.toUpperCase()) { return 1 - inversenum; }
                    diff = r1.internal_hr_id - r2.internal_hr_id;
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);

                case 'batch':
                    if (!r1.batch_prefix && !r2.batch_prefix) {
                        diff = r1.batch_number - r2.batch_number;
                        return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);
                    }
                    if (!r2.batch_prefix) { return 1 - inversenum; }
                    if (!r1.batch_prefix || r1.batch_prefix.toUpperCase() < r2.batch_prefix.toUpperCase()) { return -1 + inversenum; }
                    if (r1.batch_prefix.toUpperCase() > r2.batch_prefix.toUpperCase()) { return 1 - inversenum; }
                    diff = r1.batch_number - r2.batch_number;
                    if (diff > 0) { return 1 - inversenum; }
                    if (diff < 0) { return -1 + inversenum; }
                    diff = r1.internal_hr_id - r2.internal_hr_id;
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);

                case 'machine':
                    if (!r1.machine) { r1.machine = ''; }
                    if (!r2.machine) { r2.machine = ''; }
                    if (r1.machine.toUpperCase() < r2.machine.toUpperCase()) { return -1 + inversenum; }
                    if (r1.machine.toUpperCase() > r2.machine.toUpperCase()) { return 1 - inversenum; }
                    diff = r1.internal_hr_id - r2.internal_hr_id;
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);

                case 'user':
                    if (!r1.created_by?.nickname) { r1.created_by = { nickname: '' } as User; }
                    if (!r2.created_by?.nickname) { r2.created_by = { nickname: '' } as User; }
                    if (r1.created_by.nickname.toUpperCase() < r2.created_by.nickname.toUpperCase()) { return -1 + inversenum; }
                    if (r1.created_by.nickname.toUpperCase() > r2.created_by.nickname.toUpperCase()) { return 1 - inversenum; }
                    diff = r1.internal_hr_id - r2.internal_hr_id;
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);
            }
        });
        // if (options.origins || options.showOrganic || options.ssf) {
        //     this.doFilter(options);
        // } else {
        //     this.filteredObjects = this.cachedObjects.slice(options.pageSize * options.pageIndex, options.pageSize * (options.pageIndex + 1));
        // }
    }

    private sortObjects(options: GetPageOptions = {}): void {
        this.filtering$.next({ options, sortOnly: true, now: true });
        // this.filterObjects(options, true);
    }

    sortOrderChanged(sortVal: string): void {
        if (this.editNewRoast >= 0) {
            return;
        }
        if (this.firstSort && sortVal === 'roastdate') {
            this.lastSortValue = undefined;
            this.sortObjects({ sortOrder: sortVal, inverse: true });
            this.inverse = true;
        } else {
            if (this.lastSortValue === sortVal) {
                this.sortObjects({ sortOrder: sortVal, inverse: !this.inverse });
                this.inverse = !this.inverse;
            } else {
                this.sortObjects({ sortOrder: sortVal, inverse: false });
                this.inverse = false;
                this.lastSortValue = sortVal;
            }
        }
        this.sortValue = sortVal;
        this.firstSort = false;
    }

    objectChanged(obj: ObjectChangedInfo): void {
        if (!obj || obj.model !== 'roasts') {
            return;
        }
        // update readonly since this might have been changed (over limit)
        this.readOnly = this.userService.isReadOnly();
        this.readOnlyAccount = this.userService.isReadOnlyAccount();
        if (typeof obj.info !== 'string' && obj.info && typeof obj.info.editMode !== 'undefined') {
            this.editNewRoast = obj.info.editMode;
        } else {
            this.editNewRoast = -1;
        }
        this.isNew = -1;

        if (obj.reload) {
            this.roastFilterComponent?.reloadRoastFilterData();
            this.filtering$.next({ now: true });
            // this.getPage();
        } else if (typeof obj.info !== 'string' && typeof obj.info?.object !== 'undefined') {
            try {
                this.roastFilterComponent?.reloadRoastFilterData();

                const roast = obj.info.object as Roast;
                if (this.filteredObjects?.length > 0 && typeof this.filteredObjects[0]._id === 'undefined') {
                    // the first in the list is a new one
                    if (roast.deleted === true) {
                        // ... and it has been deleted (or the add has been cancelled)
                        this.filteredObjects.splice(0, 1);
                    } else {
                        // ... and it has been saved
                        // don't simply replace object as this will create a new subcomponent which is not expanded
                        this.utils.updateObject(this.filteredObjects[0], roast);
                        this.maxObjectCount++;
                        this.filteredObjectCount++;
                        if (this.filteredObjects.length > this.pageSize) {
                            this.filteredObjects.pop();
                        }
                        this.sortValue = 'roastdate';
                        this.lastSortValue = 'roastdate';
                        this.reloadGraphData();
                    }
                } else if (roast._id) {
                    // one object has changed / been deleted; search and update
                    for (let c = 0; c < this.filteredObjects.length; c++) {
                        if (this.utils.compareObjectsFn(this.filteredObjects[c], roast)) {
                            if (roast.deleted === true) {
                                this.filteredObjects.splice(c, 1);
                                this.maxObjectCount--;
                                this.filteredObjectCount--;
                            } else {
                                // don't simply replace object as this will create a new subcomponent which is not expanded
                                this.utils.updateObject(this.filteredObjects[c], roast);
                            }
                            break;
                        }
                    }
                    this.reloadGraphData();
                } else {
                    // cannot associate changed object
                    this.getPage();
                }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (err) {
                // ignore but refresh to be on the save side
                this.getPage();
            }
        }
    }

    private labelExists(label: string): boolean {
        for (let r = 0; r < this.filteredObjects?.length; r++) {
            const obj = this.filteredObjects[r];
            if (obj?.label === label) {
                return true;
            }
        }
        return false;
    }

    add(): void {
        if (this.readOnly) { return; }

        if (!this.filteredObjects) {
            this.filteredObjects = [];
        }
        let cnt = 0;
        let label = this.tr.anslate('NEW');
        while (this.labelExists(label)) {
            cnt++;
            label = this.tr.anslate('NEW') + cnt;
        }

        this.editNewRoast = 0;
        this.isNew = 0;
        const roast = new Roast();
        // pre-selet first store
        if (this.stores && this.stores.length) {
            roast.location = this.stores[0] as unknown as Location;
        }
        roast.label = label;
        roast.amount = 1;
        if (this.currentUser) {
            roast.amount /= this.utils.getUnitFactor(this.currentUser.unit_system);
        }
        this.filteredObjects.unshift(roast);
    }
}
