import { Component, OnInit, OnDestroy, Inject, LOCALE_ID, ViewChild, ElementRef } from '@angular/core';
import { StandardService } from 'src/app/util/services/standard.service';
import { Blend } from 'src/app/models/Blend';
import { Utils } from 'src/app/util/utils';
import { ObjectChangedInfo, ObjectChangedService } from 'src/app/util/services/objectchanged.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Location } from 'src/app/models/Location';
import { ReportService } from 'src/app/modules/report/report.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { DateTime } from 'luxon';
import { ClipboardService } from 'ngx-clipboard';
import { PageEvent } from '@angular/material/paginator';
import { Constants } from 'src/app/util/constants';

@Component({
    selector: 'app-blends',
    templateUrl: './blends.component.html',
    styleUrls: ['./blends.component.scss'],
    providers: []
})
export class BlendsComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private standardService: StandardService,
        private objectChangedService: ObjectChangedService,
        private tr: TranslatorService,
        private reportService: ReportService,
        public utils: Utils,
        private clipboardService: ClipboardService,
        private alertService: AlertService,
        @Inject(LOCALE_ID) public locale: string
    ) { }

    blends: Blend[];
    cachedObjects: Blend[] = [];
    objectCount = 0;
    pageSize = 10;
    pageIndex = 0;
    editNewBlend = -1;
    isNew = -1;
    idToHighlight: string;

    sortValue: string;
    lastSortValue: string;
    firstSort = true;
    inverse = false;

    creating = false;
    loadingCoffees = false;
    showstockfrom: { _id: string, hr_id?: string, label?: string }[] | 'all' = 'all';
    allStores: { _id: string, hr_id?: string, label?: string }[] = [];

    currentUser: UserType;
    readOnly = false;

    exportFormat: string;

    anyBlendHasStock = true;

    loadSubscription = new Subject<string>();
    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();

    @ViewChild('appBlends') blendsElem: ElementRef;


    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        this.pageSize = this.userService.getPageSize('blends', this.pageSize);

        // directly read ssf; cannot use this.utils.readShowStockFrom bc we don't have this.allStores yet
        const ssf = localStorage.getItem('stockfrom_' + this.currentUser.user_id);
        if (ssf === 'all') {
            this.showstockfrom = 'all';
        } else if (ssf) {
            this.showstockfrom = ssf.split(Constants.SSF_SEPARATOR).map(s => { return { _id: s, label: '' }; });
        }

        this.objectChangedService.changed$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                obj => this.objectChanged(obj)
            );

        this.loadSubscription
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                // TODO move into getAll ?
                this.readOnly = this.userService.isReadOnly();
                this.sortValue = 'lastmodified';
                this.lastSortValue = 'lastmodified';

                this.cachedObjects.length = 0;

                this.editNewBlend = -1;
                this.isNew = -1;
                this.pageIndex = 0;

                this.idToHighlight = this.route.snapshot.params.id;
                if (this.idToHighlight) {
                    // have a direct link; need to get the corresponding page
                    this.getPageForId(this.idToHighlight);
                } else {
                    this.getAllPaged(this.pageSize, 0);
                }

                this.getAllStores();
            }
            );

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: unknown) => {
                if (e instanceof NavigationEnd && e.url.indexOf('blends') >= 0) {
                    // only pass and debounce interesting events
                    this.loadSubscription.next('reload');
                }
            }
            );

        this.sortValue = 'lastmodified';
        this.lastSortValue = 'lastmodified';

        if (this.currentUser) {
            this.loadSubscription.next('init');
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    private updateAnyCoffeeHasStock() {
        if (!this.blends?.length) {
            this.anyBlendHasStock = false;
            return;
        }

        // necessary to have the coffee components calc .curStock
        setTimeout(() => {
            this.anyBlendHasStock = true;
            for (let c = 0; c < this.blends.length; c++) {
                const blend = this.blends[c];
                if (blend.curStock > 0) {
                    return;
                }
            }
            this.anyBlendHasStock = false;
        }, 10);
    }

    getAllPaged(pageSize: number = this.pageSize, pageIndex: number = this.pageIndex,
        sortValue: string = this.sortValue, inverse: boolean = this.inverse, ssf: { _id: string }[] | 'all' = this.showstockfrom): void {
        // check cache first
        if ((this.lastSortValue !== this.sortValue || this.inverse !== inverse
            || (this.sortValue === 'stock' && ((this.showstockfrom === 'all' && ssf !== 'all') || (this.showstockfrom !== 'all' && ssf === 'all')
                || this.showstockfrom.length !== ssf.length)))
            && this.countCached() === this.objectCount) {
            // new sort order but have all in cache; use cache and sort here
            this.clientSideSort(this.sortValue, inverse, ssf, pageSize, pageIndex);
            return;
        }

        const firstIndex = pageSize * pageIndex;
        const lastIndex = Math.min(pageSize * (pageIndex + 1) - 1, this.objectCount - 1);

        if (this.objectCount > 0
            && this.lastSortValue === this.sortValue && this.inverse === inverse
            && (this.sortValue !== 'stock'
                || (this.sortValue === 'stock' && ((this.showstockfrom === 'all' && ssf === 'all') || (Array.isArray(this.showstockfrom) && Array.isArray(ssf) && this.showstockfrom.length === ssf.length))))
            && this.cachedObjects[firstIndex] && this.cachedObjects[lastIndex]) {

            // have objects in cache and sort order didn't change; use cache
            this.blends = this.cachedObjects.slice(pageSize * pageIndex, pageSize * (pageIndex + 1));
            this.updateAnyCoffeeHasStock();

            this.preLoadObjects(pageSize, pageIndex, sortValue, inverse, ssf);
            return;
        }

        this.standardService.getPage<Blend>('blends', pageSize, pageIndex, sortValue, inverse, ssf)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.readOnly = this.userService.isReadOnly();
                    if (response.success === true) {
                        this.blends = response.result;
                        this.updateAnyCoffeeHasStock();
                        if (this.objectCount !== response.count) {
                            // something changed, clear the cache
                            this.objectCount = response.count;
                            this.cachedObjects.length = 0;
                        }
                        if (this.cachedObjects.length < pageSize * pageIndex) {
                            // nothing to delete but need to insert at correct place in array; splice doesn't do this
                            let cnt = 0;
                            for (let i = pageSize * pageIndex; i < pageSize * pageIndex + this.blends.length; i++) {
                                this.cachedObjects[i] = this.blends[cnt++];
                            }
                        } else {
                            this.cachedObjects.splice(pageSize * pageIndex, this.blends.length, ...this.blends);
                        }
                        this.preLoadObjects(pageSize, pageIndex, sortValue, inverse, ssf);

                    } else {
                        this.utils.handleError('error retrieving all blends', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all blends', error);
                }
            });
    }

    getPageForId(id: string): void {
        // check cache first
        let index = -1;
        if (id) {
            id = id.trim();
            for (let i = 0; i < this.cachedObjects.length; i++) {
                const obj = this.cachedObjects[i];
                if (obj.label === id) {
                    index = i;
                    break;
                }
                let idLabel = '_id';
                if (/B\d+/.test(id)) {
                    idLabel = 'hr_id';
                }
                if (obj[idLabel] === id) {
                    index = i;
                    break;
                }
            }
        }
        if (index >= 0) {
            // retrieve the corresponding page from the cache
            this.getAllPaged(this.pageSize, Math.floor(index / this.pageSize));
        } else {
            // need to get the corresponding page from the server
            this.standardService.getPageForId<Blend>('blends', this.pageSize, id, this.sortValue, this.inverse, this.showstockfrom)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        this.readOnly = this.userService.isReadOnly();
                        if (response.success === true) {
                            this.blends = response.result.objects;
                            this.updateAnyCoffeeHasStock();
                            this.pageIndex = response.result.pageIndex;
                            if (this.objectCount !== response.count) {
                                // something changed, clear the cache
                                this.objectCount = response.count;
                                this.cachedObjects.length = 0;
                            }
                            if (this.cachedObjects.length < this.pageSize * this.pageIndex) {
                                // nothing to delete but need to insert at correct place in array; splice doesn't do this
                                let cnt = 0;
                                for (let i = this.pageSize * this.pageIndex; i < this.pageSize * this.pageIndex + this.blends.length; i++) {
                                    this.cachedObjects[i] = this.blends[cnt++];
                                }
                            } else {
                                this.cachedObjects.splice(this.pageSize * this.pageIndex, this.blends.length, ...this.blends);
                            }
                            this.preLoadObjects(this.pageSize, this.pageIndex, this.sortValue, this.inverse, this.showstockfrom);

                        } else {
                            this.utils.handleError('error retrieving all blends', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving all blends', error);
                    }
                });
        }
    }

    countCached(): number {
        let cnt = 0;
        for (let cr = 0; cr < this.cachedObjects.length; cr++) {
            if (this.cachedObjects[cr]) {
                cnt++;
            }
        }
        return cnt;
    }

    preLoadObjects(pageSize: number, pageIndex: number, sortValue: string, inverse: boolean, ssf: { _id: string }[] | 'all'): void {
        if (this.objectCount === 0 || this.countCached() === this.objectCount) {
            return;
        }

        if (this.objectCount <= Constants.DOWNLOADALL_THRESHOLD) {
            // get all at once in the background
            this.standardService.getPage<Blend>('blends', Constants.DOWNLOADALL_THRESHOLD, 0, sortValue, inverse, ssf)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        this.readOnly = this.userService.isReadOnly();
                        if (response.success === true) {
                            this.cachedObjects = response.result;
                            if (this.objectCount !== response.count) {
                                // something changed in the meantime
                                this.blends = this.cachedObjects.slice(
                                    Math.min(this.cachedObjects.length, pageSize * pageIndex),
                                    Math.min(this.cachedObjects.length, pageSize * pageIndex + this.blends.length));
                                this.objectCount = response.count;
                                this.updateAnyCoffeeHasStock();
                            }
                        }
                    }
                });

        } else {
            // get at least the next / previous page; make sure it can be cancelled
            let firstIndex = pageSize * (pageIndex + 1);
            let lastIndex = Math.min(pageSize * (pageIndex + 2) - 1, this.objectCount - 1);

            if (firstIndex < this.objectCount - 1 &&
                (!this.cachedObjects[firstIndex] || !this.cachedObjects[lastIndex])) {

                // there is a next page and it is not in the cache
                this.standardService.getPage<Blend>('blends', pageSize, pageIndex + 1, sortValue, inverse, ssf)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.cachedObjects.splice(pageSize * (pageIndex + 1), response.result.length, ...response.result);
                                if (this.objectCount !== response.count) {
                                    // something changed in the meantime, reload the current page
                                    this.standardService.getPage<Blend>('blends', pageSize, pageIndex, sortValue, inverse, ssf)
                                        .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                        .subscribe({
                                            next: response2 => {
                                                if (response2.success === true) {
                                                    this.cachedObjects.splice(pageSize * pageIndex, response2.result.length, ...response2.result);
                                                    this.objectCount = response2.count;
                                                }
                                            }
                                        });
                                }
                            }
                        }
                    });
            }

            firstIndex = pageSize * (pageIndex - 1);
            lastIndex = Math.min(pageSize * pageIndex, this.objectCount - 1);

            if (pageIndex > 0 &&
                (!this.cachedObjects[firstIndex] || !this.cachedObjects[lastIndex])) {
                // there is a previous page and it is not in the cache
                this.standardService.getPage<Blend>('blends', pageSize, pageIndex - 1, sortValue, inverse, ssf)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.cachedObjects.splice(pageSize * (pageIndex - 1), response.result.length, ...response.result);
                                if (this.objectCount !== response.count) {
                                    // something changed in the meantime, reload the current page
                                    this.standardService.getPage<Blend>('blends', pageSize, pageIndex, sortValue, inverse, ssf)
                                        .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                        .subscribe({
                                            next: response2 => {
                                                if (response2.success === true) {
                                                    this.cachedObjects.splice(pageSize * pageIndex, response2.result.length, ...response2.result);
                                                    this.objectCount = response2.count;
                                                }
                                            }
                                        });
                                }
                            }
                        }
                    });
            }
        }
    }

    pagingChanged($event: PageEvent): void {
        if (this.pageSize !== $event.pageSize) {
            this.pageSize = $event.pageSize;
            this.userService.setPageSize('blends', this.pageSize);
        }
        this.pageIndex = $event.pageIndex;
        this.getAllPaged();
    }

    clientSideSort(sortValue: string, inverse: boolean, ssf: { _id: string }[] | 'all', pageSize: number, pageIndex: number): void {
        const inversenum = inverse ? 2 : 0;
        if (sortValue === 'stock') {
            // set .filteredStock to the sum of stock (taking showstockfrom into account)
            for (let b = 0; b < this.cachedObjects.length; b++) {
                const blend = this.cachedObjects[b];
                const ssfStr = ssf === 'all' ? ssf : ssf.map(l => l._id.toString());
                blend['filteredStock'] = Math.max(this.utils.getBlendStock(blend, ssfStr), 0);
            }
        }
        this.cachedObjects.sort((b1, b2) => {
            let diff = 0;
            let diff2 = 0;
            switch (sortValue) {
                case undefined:
                case 'lastmodified':
                    if (!b1.updated_at && !b2.updated_at) { return 0; }
                    if (!b2.updated_at) { return 1 - inversenum; }
                    if (!b1.updated_at) { return -1 + inversenum; }
                    diff = new Date(b1.updated_at).getTime() - new Date(b2.updated_at).getTime();
                    if (diff < 0) { return 1 - inversenum; }
                    if (diff > 0) { return -1 + inversenum; }
                    diff = b1.internal_hr_id - b2.internal_hr_id;
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);

                case 'hr_id':
                    diff = b2.internal_hr_id - b1.internal_hr_id;
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);

                case 'label':
                    if (!b1.label) { b1.label = ''; }
                    if (!b2.label) { b2.label = ''; }
                    if (b1.label.toUpperCase() < b2.label.toUpperCase()) { return -1 + inversenum; }
                    if (b1.label.toUpperCase() > b2.label.toUpperCase()) { return 1 - inversenum; }
                    diff = b2.internal_hr_id - b1.internal_hr_id;
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);

                case 'stock':
                    diff = b1['filteredStock'] - b2['filteredStock'];
                    diff2 = b2.internal_hr_id - b1.internal_hr_id;
                    return diff > 0 ? -1 + inversenum : (diff < 0 ? 1 - inversenum : (diff2 > 0 ? -1 + inversenum : (diff2 < 0 ? 1 - inversenum : 0)));
            }
        });
        this.blends = this.cachedObjects.slice(pageSize * pageIndex, pageSize * (pageIndex + 1));
        this.updateAnyCoffeeHasStock();
    }

    sortBlends(inverse: boolean, ssf: { _id: string }[] | 'all'): void {
        if (this.countCached() !== this.objectCount) {
            // clear cache since sort order changed and not all are cached
            this.cachedObjects.length = 0;
        }
        this.getAllPaged(this.pageSize, this.pageIndex, this.sortValue, inverse, ssf);
    }

    showstockfromChanged($event: { value: { _id: string, hr_id?: string, label?: string }[] | 'all' }): void {
        if (!$event.value?.length || $event.value === 'all' || $event.value.length === this.allStores.length) {
            this.utils.storeShowStockFrom('all', this.currentUser);
            $event.value = 'all';
        } else {
            this.utils.storeShowStockFrom($event.value, this.currentUser);
        }
        // check cache first
        if (this.countCached() === this.objectCount) {
            // new filter but have all in cache; use cache and sort here
            this.clientSideSort(this.sortValue, this.inverse, $event.value, this.pageSize, this.pageIndex);
        } else {
            this.sortBlends(this.inverse, $event.value);
        }
        // set after sorting such that sorting still knows the previous sort values
        this.showstockfrom = $event.value;
        setTimeout(() => {
            this.updateAnyCoffeeHasStock();
        }, 10);
    }

    sortOrderChanged(): void {
        if (this.editNewBlend >= 0) {
            return;
        }
        if (this.firstSort && this.sortValue === 'lastmodified') {
            this.lastSortValue = undefined;
            this.sortBlends(true, this.showstockfrom);
            this.inverse = true;
        } else {
            if (this.lastSortValue === this.sortValue) {
                this.sortBlends(!this.inverse, this.showstockfrom);
                this.inverse = !this.inverse;
            } else {
                this.sortBlends(false, this.showstockfrom);
                this.inverse = false;
                this.lastSortValue = this.sortValue;
            }
        }
        this.firstSort = false;
    }

    objectChanged(obj: ObjectChangedInfo): void {
        if (!obj || obj.model !== 'blends') {
            return;
        }
        if (typeof obj.info !== 'string' && obj.info && typeof obj.info.editMode !== 'undefined') {
            this.editNewBlend = obj.info.editMode;
        } else {
            this.editNewBlend = -1;
        }
        this.isNew = -1;

        if (obj.reload) {
            this.cachedObjects.length = 0;
            this.getAllPaged();
            return;
        }

        if (typeof obj.info !== 'string' && obj.info && typeof obj.info.object !== 'undefined') {
            try {
                const blend = obj.info.object as Blend;
                if (this.blends.length > 0 && typeof this.blends[0]._id === 'undefined') {
                    // the first in the list is a new one
                    if (blend.deleted === true) {
                        // ... and it has been deleted (or the add has been cancelled)
                        this.blends.splice(0, 1);
                    } else {
                        // ... and it has been saved
                        // don't simply replace object as this will create a new subcomponent which is not expanded
                        this.utils.updateObject(this.blends[0], blend);
                        this.sortValue = undefined;
                        this.lastSortValue = 'lastmodified';
                        this.objectCount += 1;
                    }
                    this.updateAnyCoffeeHasStock();
                } else if (blend._id) {
                    for (let c = 0; c < this.blends.length; c++) {
                        if (this.blends[c]._id === blend._id) {
                            if (blend.deleted === true) {
                                this.blends.splice(c, 1);
                            } else {
                                // don't simply replace object as this will create a new subcomponent which is not expanded
                                this.utils.updateObject(this.blends[c], blend);
                            }
                            break;
                        }
                    }
                    this.updateAnyCoffeeHasStock();
                } else {
                    // cannot associate changed object
                    this.cachedObjects.length = 0;
                    this.getAllPaged();
                }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (err) {
                // ignore but refresh to be on the save side
                this.cachedObjects = [];
                this.getAllPaged();
            }
        }
    }

    getAllStores(): void {
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            // TODO .pipe(finalize(() => /* duplicate code block */))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.allStores = (response.result as Location[]).map(s => {
                            return { _id: s._id, hr_id: s.hr_id, label: s.label }
                        });
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                        if (!this.allStores?.length) {
                            this.allStores = [];
                        }
                    }
                    this.allStores.sort((s1, s2) => s1.hr_id > s2.hr_id ? -1 : s1.hr_id > s2.hr_id ? 1 : 0);
                    this.showstockfrom = this.utils.readShowStockFrom(this.allStores, this.currentUser);
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                    if (!this.allStores?.length) {
                        this.allStores = [];
                    }
                }
            });
    }

    private labelExists(label: string): boolean {
        for (const blend of this.blends) {
            if (blend.label === label) {
                return true;
            }
        }
        return false;
    }

    blendStockChanged() {
        this.updateAnyCoffeeHasStock();
    }

    add(): void {
        if (this.readOnly) { return; }

        if (!this.blends) {
            this.blends = [];
        }
        let cnt = 0;
        let label = this.tr.anslate('NEW');
        while (this.labelExists(label)) {
            cnt++;
            label = this.tr.anslate('NEW') + '-' + cnt;
        }

        this.editNewBlend = 0;
        this.isNew = 0;
        const blend = new Blend();
        blend.label = label;
        blend.ingredients = [{ coffee: undefined, ratio: 1.0 }];

        this.blends.unshift(blend);
    }

    export(format: 'csv' | 'pdf' | 'clipboardCSV'): void {
        this.generateData(format);
        // this.exportFormat = null;
        setTimeout(() => this.exportFormat = null, 1000);
    }

    generateData(format: 'csv' | 'pdf' | 'clipboardCSV'): void {
        if (this.readOnly) { return; }

        this.creating = true;

        const ssfStr = this.showstockfrom === 'all' ? 'all' : this.showstockfrom.map(l => l._id.toString()).join(Constants.SSF_SEPARATOR);
        if (format === 'csv' || format === 'pdf') {
            this.reportService.getBlendsData(format, ssfStr, this.sortValue, this.inverse, this.locale)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: blob => {
                        this.utils.saveBlobToFileSystem(blob, this.tr.anslate('Blends') + '_' + DateTime.now().toFormat('yyyyMMdd') + '.' + format);
                        this.creating = false;
                        this.alertService.success(this.tr.anslate('Successfully added'));
                    },
                    error: error => {
                        this.creating = false;
                        this.utils.handleError('error creating CSV', error);
                    }
                });
        } else {
            this.reportService.getBlendsDataCopy(format, ssfStr, this.sortValue, this.inverse, this.locale)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response?.success && response.result && this.blendsElem) {
                            if (this.clipboardService.copyFromContent(response.result, this.blendsElem?.nativeElement)) {
                                setTimeout(() => {
                                    this.alertService.success(this.tr.anslate('Successfully added'));
                                }, 750);
                            } else {
                                this.utils.handleError('Error copying to Clipboard - Please use CSV and save as a file');
                            }
                        } else {
                            this.utils.handleError('error creating CSV');
                        }
                        this.creating = false;
                    },
                    error: error => {
                        this.creating = false;
                        this.utils.handleError('error creating CSV', error);
                    }
                });
        }
    }
}
