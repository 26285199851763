import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { RoastSchedule } from 'src/app/models/RoastSchedule';
import { RoastScheduledItem } from 'src/app/models/RoastScheduledItem';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SchedulerService {

    constructor(
        private http: HttpClient,
        private utils: Utils,
        private tr: TranslatorService,
        @Inject(LOCALE_ID) public locale: string,
    ) {}

    getSchedule(date: string, prevDays = 0, nextDays = 0): Observable<{ success: boolean, result: RoastSchedule[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/' + (date ?? '');
        let params = new HttpParams();
        if (prevDays) {
            params = params.set('prevDays', prevDays);
        }
        if (nextDays) {
            params = params.set('nextDays', nextDays);
        }
        return this.http.get<{ success: boolean, result: RoastSchedule[], error: string }>(url, { params });
    }

    // would need the "today" param
    // saveSchedule(schedule: RoastSchedule): Observable<{ success: boolean, result: RoastSchedule, error: string }> {
    //     const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule';
    //     return this.http.post<{ success: boolean, result: RoastSchedule, error: string }>(url, schedule);
    // }

    /**
     * Adds a RoastScheduledItem to the Schedule (with the date found in the item
     * - or today if none found) at the given index (appends if not given).
     * @param {RoastScheduledItem} item the new item
     * @param {number} [index] the index to insert the item
     * @returns {Observable<{ success: boolean, result: string, error: string }>} with result being the new _id of the created item
     */
    addItem(item: RoastScheduledItem, index: number): Observable<{ success: boolean, result: string, error: string }> {
        const today = DateTime.now().toISODate();
        const date = item.date ?? today;
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/item/' + date + '/' + today;
        return this.http.post<{ success: boolean, result: string, error: string }>(url, { item, index });
    }

    /**
     * Adds several RoastScheduledItems to the Schedule (with the date found
     * in the first item - or today if none found).
     * One cannot add items to multiple dates with this method.
     * @param {RoastScheduledItem[]} items the new items
     * @returns {Observable<{ success: boolean, result: string[], error: string }>} with result being the new _ids of the created items
     */
    addItems(items: RoastScheduledItem[]): Observable<{ success: boolean, result: string[], error: string }> {
        const today = DateTime.now().toISODate();
        const date = items[0]?.date ?? today;
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/items/' + date + '/' + today;
        return this.http.post<{ success: boolean, result: string[], error: string }>(url, { items });
    }

    updateItem(schedule: RoastSchedule, item: RoastScheduledItem): Observable<void | { success: boolean, result: RoastScheduledItem, error: string }> {
        const today = DateTime.now().toISODate();
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/item/' + schedule.date + '/' + today;
        return this.http.put<void | { success: boolean, result: RoastScheduledItem, error: string }>(url, item);
    }

    /**
     * Deletes one item from a schedule
     * @param {string} date the date of the schedule from which to delete the item
     * @param {number} itemIdx the index of the item to delete
     * @returns {Observable<{ success: boolean, result: number, error: string }>} with result being the number of items left after the delete
     */
    deleteItem(date: string, itemIdx: number): Observable<{ success: boolean, result: number, error: string }> {
        const today = DateTime.now().toISODate();
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/item/' + date + '/' + itemIdx + '/' + today;
        return this.http.delete<{ success: boolean, result: number, error: string }>(url);
    }

    /**
     * Deletes all items from a schedule
     * @param {string} date the date of the schedule from which to delete the item
     * @returns {Observable<{ success: boolean, result: number, error: string }>} with result being the number of items left after the delete
     */
    deleteSchedule(date: string): Observable<{ success: boolean, result: number, error: string }> {
        const today = DateTime.now().toISODate();
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/items/' + date + '/' + today;
        return this.http.delete<{ success: boolean, result: number, error: string }>(url);
    }

    /**
     * Adds items that are clones of the given item on each of the given dates
     * @param {DateTime[]} dates the dates on which items will be added
     * @param {string} itemDate the date of the schedule (in which the itemId can be found)
     * @param {string} itemId the item _id to clone
     * @returns {Observable<{ success: boolean, result: number, error: string }>} with result being the number of deleted items
     */
    addClones(dates: DateTime[], itemDate: string, itemId?: string): Observable<{ success: boolean, result: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/clones';
        return this.http.post<{ success: boolean, result: number, error: string }>(url, { dates: JSON.stringify((dates ?? []).map(d => d.toISODate())), itemDate, itemId });
    }

    /**
     * Deletes all future items that are clones of the given item
     * @param {DateTime[]} dates the dates on which items will be deleted
     * @param {string} itemDate the date of the schedule (in which the itemId can be found)
     * @param {string} itemId the item _id to clone
     * @returns {Observable<{ success: boolean, result: number, error: string }>} with result being the number of deleted items
     */
    deleteClones(dates: DateTime[], itemDate: string, itemId?: string): Observable<{ success: boolean, result: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/clones';
        return this.http.delete<{ success: boolean, result: number, error: string }>(url, { body: { dates: JSON.stringify((dates ?? []).map(d => d.toISODate())), itemDate, itemId } });
    }

    /**
     * Stores a list of favorite items.
     * @param {number} line the number of the bucket to save
     * @returns {Observable<{ success: boolean, result: number, error: string }>} with result being the number of items stored
     */
    saveFavorites(items: string, line: number): Observable<{ success: boolean, result: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/favorites/' + line;
        return this.http.post<{ success: boolean, result: number, error: string }>(url, { items });
    }

    /**
     * Loads the list of favorite items.
     * @param {number} line the number of the bucket to load
     * @returns {Observable<{ success: boolean, result: string, error: string }>} with result being the JSON string of the array of items loaded
     */
    loadFavorites(line: number): Observable<{ success: boolean, result: string, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/favorites/' + line;
        return this.http.get<{ success: boolean, result: string, error: string }>(url);
    }

    moveItem(to: number, toDate: string, from: number, fromDate?: string, clone: boolean = false): Observable<{ success: boolean, result: '', error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastschedule/itemorder';
        let params = new HttpParams();
        params = params.set('today', DateTime.now().toISODate());
        params = params.set('toDate', toDate);
        params = params.set('from', from);
        params = params.set('to', to);
        if (fromDate) {
            params = params.set('fromDate', fromDate);
        }
        if (clone) {
            params = params.set('clone', '1');
        }
        return this.http.put<{ success: boolean, result: '', error: string }>(url, undefined, { params });
    }

    public addInfoAndSummary(schedule: RoastSchedule, filteredItems: RoastScheduledItem[], mainUnit: UnitSystemType) {
        // TODO this should not be necessary but items are missing
        // the .itemAmount after a filter change
        for (let i = 0; i < schedule.items?.length; i++) {
            const item = schedule.items[i];
            this.addInfo(item, mainUnit);
        }
        for (let i = 0; i < filteredItems?.length; i++) {
            const item = filteredItems[i];
            this.addInfo(item, mainUnit);
        }
        this.addSummary(schedule, filteredItems, mainUnit);
    }

    /**
     * Adds additional info to an item for display.
     * Including subtitle (smaller font) and info (tooltip).
     * @param {RoastScheduledItem} item 
     */
    public addInfo(item: RoastScheduledItem, mainUnit: UnitSystemType): void {
        if (!item) {
            return;
        }

        item.itemAmount = this.utils.formatAmount(item.amount, undefined, mainUnit);
        item.marginRightForDelete = mainUnit === 'g' || (mainUnit === 'kg' && !item.itemAmount.includes('kg')) ? '-2px' : (mainUnit === 'oz' || (mainUnit.includes('lb') && !item.itemAmount.includes('lb')) ? '8px' : undefined);

        let info1 = '';
        // only add coffee / blend label if not contained in title already
        if (item.coffee?.label && item.title?.toLocaleUpperCase()?.indexOf(item.coffee.label.toLocaleUpperCase()) < 0) {
            info1 += `${item.coffee.origin ? this.tr.anslate(item.coffee.origin) : ''}${item.coffee.yearLabel ? ' ' : ''}${item.coffee.yearLabel}${item.coffee.origin || item.coffee.yearLabel ? ', ' : ''}${item.coffee.label}`;
        } else if (item.blend?.label && item.title?.toLocaleUpperCase()?.indexOf(item.blend.label.toLocaleUpperCase()) < 0) {
            info1 += item.blend.label;
        }
        if (item.machine) {
            info1 += `${info1 ? '\n\n' : ''}${item.machine}`;
        }
        if (item.nickname) {
            info1 += `${info1 ? ' • ' : ''}${item.nickname}`;
        }
        item.info1 = info1;
        // item.tooltip = item.info1;

        let info2 = '';
        if (item.template?.label) {
            info2 += `${info2 ? '\n' : ''}${item.template.label}`;
        }
        if (item.location) {
            info2 += `${info2 ? '\n' : ''}${item.location.label}`;
        }
        if (item.roasts?.length) {
            item.roasted = 0;
            for (let r = 0; r < item.roasts.length; r++) {
                const roast = item.roasts[r];
                item.roasted += roast?.amount || 0;
            }
            let roastedInfo: string;
            if (item.roasts.length === item.count && Math.abs((item.roasted - item.count * item.amount) / item.roasted) < 0.01) {
                roastedInfo = `✓ ${this.utils.formatAmount(item.roasted, undefined, mainUnit)} ${this.tr.anslate('roasted')}`;
            } else {
                roastedInfo = `${item.roasts.length} ${this.tr.anslate('of')} ${item.count}  ${this.tr.anslate('roasted')}`;
                if (item.roasted) {
                    roastedInfo += `, ${this.utils.formatAmount(item.roasted, undefined, mainUnit, -1, false)} ${this.tr.anslate('of')} ${this.utils.formatAmount(item.count * item.amount, undefined, mainUnit)}`;
                }
            }
            info2 += `${info2 ? '\n\n' : ''}${roastedInfo}`;
            // item.tooltip += `${item.tooltip ? '\n' : ''}${roastedInfo || ''}`;
        }
        item.info2 = info2;

        // if (item.blend?.label) {
        //     item.blendInfo = this.utils.getBlendStr(item.blend, '\n', false, item.amount, mainUnit);
        // }
        // item.tooltip = item.blendInfo || '';

        item.tooltip = item.info1 || '';
        item.tooltip += `${item.tooltip ? '\n\n' : ''}${item.info2 || ''}`;
    }

    /**
     * Adds summary info (total count, amount) to the given schedule.
     */
    public addSummary(schedule: RoastSchedule, filteredItems: RoastScheduledItem[], mainUnit: UnitSystemType): void {
        if (!schedule) {
            return;
        }

        const date = DateTime.fromISO(schedule.date);
        schedule.weekday = date.weekdayLong?.toLocaleUpperCase(this.locale);
        schedule.weekdayShort = date.weekdayShort?.toLocaleUpperCase(this.locale);

        let count = 0;
        let amount = 0;
        let doneCount = 0;
        let doneAmount = 0;
        let time = 0;
        let doneTime = 0;
        let haveAllDoneRoastTimes = true;
        let haveAllRoastTimes = true;
        for (let i = 0; i < filteredItems?.length; i++) {
            const item = filteredItems[i];
            if (!item.roasts?.length) {
                if (item?.template?.drop_time) {
                    time += item.count * item.template.drop_time;
                } else {
                    haveAllRoastTimes = false;
                }
            } else {
                let lastDropTime: number;
                for (let r = 0; r < item.roasts.length; r++) {
                    const roast = item.roasts[r];
                    if (roast.drop_time) {
                        lastDropTime = roast.drop_time;
                        time += roast.drop_time;
                        doneTime += roast.drop_time;
                    } else if (lastDropTime) {
                        // if there is a roast without time info, estimate with the time of the last roast
                        time += lastDropTime;
                        doneTime += lastDropTime;
                    } else if (item?.template?.drop_time) {
                        // if no previous roast has time info, use template if available
                        // (there could be a roast with time info later in the array but this case is unrealistic)
                        time += item.template.drop_time;
                        doneTime += item.template.drop_time;
                    } else {
                        haveAllDoneRoastTimes = false;
                    }
                }
                if (item.count > item.roasts.length) {
                    // use the time of the last roast to estimate time for the missing remaining roasts
                    time += (item.count - item.roasts.length) * lastDropTime;
                }
            }
            count += item?.count ?? 1;
            const itemAmount = (item?.count ?? 1) * (item?.amount ?? 0);
            amount += itemAmount;
            doneCount += item?.roasts?.length ?? 0;
            doneAmount += item?.roasted ?? 0;
            if ((item?.roasted ?? 0) > itemAmount) {
                // add surplus roasted amount to total amount
                amount += item.roasted - itemAmount;
            }
        }
        if (count) {
            let timeStr = '';
            if (haveAllDoneRoastTimes && doneTime) {
                if (doneTime / 60 > 90) {
                    timeStr = `${Math.floor(doneTime / 3600.0)}${this.tr.anslate('h')}`;
                    if (Math.round((doneTime % 3600.0) / 60.0)) {
                        timeStr += `${Math.round((doneTime % 3600.0) / 60.0)}${this.tr.anslate('min')}`;
                    }
                    timeStr += ` ${this.tr.anslate('roasted')}`;
                } else {
                    timeStr = `${Math.ceil(doneTime / 60.0)} ${this.tr.anslate('min')} ${this.tr.anslate('roasted')}`;
                }
                schedule.timeStr = timeStr;
            }
            if (haveAllRoastTimes && time) {
                if (haveAllDoneRoastTimes && doneTime) {
                    timeStr += ` ${this.tr.anslate('of')} `;
                }
                if (time / 60 > 90) {
                    timeStr += `${Math.floor(time / 3600.0)}${this.tr.anslate('h')}`;
                    if (Math.round((time % 3600.0) / 60.0)) {
                        timeStr += `${Math.round((time % 3600.0) / 60.0)}${this.tr.anslate('min')}`;
                    }
                } else {
                    timeStr += `${Math.ceil(time / 60.0)} ${this.tr.anslate('min')}`;
                }
                schedule.timeStr = timeStr;
            }

            // eslint-disable-next-line max-len
            // const summary = `${count} ${count === 1 ? this.tr.anslate('roast') : this.tr.anslate('Roasts')} • ${this.utils.formatAmount(amount, undefined, mainUnit)}${timeStr ? ` • ${timeStr}` : ''}`;
            let summary: string;
            if (doneCount) {
                // eslint-disable-next-line max-len
                summary = `${doneCount} ${this.tr.anslate('of')} ${count} ${count === 1 ? this.tr.anslate('roast') : this.tr.anslate('Roasts')} • ${this.utils.formatAmount(doneAmount, undefined, mainUnit, -1, false)} ${this.tr.anslate('of')} ${this.utils.formatAmount(amount, undefined, mainUnit)}`;
                schedule.summaryMin = `${this.utils.formatAmount(doneAmount, undefined, mainUnit)}`;
            } else {
                summary = `${count} ${count === 1 ? this.tr.anslate('roast') : this.tr.anslate('Roasts')} • ${this.utils.formatAmount(amount, undefined, mainUnit)}`;
                schedule.summaryMin = `${this.utils.formatAmount(amount, undefined, mainUnit)}`;
            }
            schedule.summary = summary;
        } else {
            schedule.summary = undefined;
        }
    }

    public calculateStock(items: RoastScheduledItem[], mainUnit: UnitSystemType): void {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const store = item.location?._id?.toString();

            const showstockfrom: 'all' | string[] = store ? [store] : 'all';
            if (item.coffee) {
                const cof = item.coffee;
                if (!cof.totalStock) {
                    const stk = this.utils.getCoffeeStock(cof, showstockfrom);
                    cof.totalStock = stk;
                    cof.totalStockStr = this.utils.formatAmount(stk, undefined, mainUnit, 1);
                }
            } else if (item.blend) {
                const blnd = item.blend;
                if (!blnd.totalStock) {
                    const stk = this.utils.getBlendStock(blnd, showstockfrom);
                    blnd.totalStock = stk;
                    blnd.totalStockStr = this.utils.formatAmount(stk, undefined, mainUnit, 1);
                }
            }
        }
    }
}
