import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { Observable } from 'rxjs';
import { Error } from 'src/app/models/Error';
import { throttleTime } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root'
})
export class ServerLogService {

    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
        private userService: UserService,
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    errorOccurred(errorObj: Error, loc: string): Observable<{ success: boolean, result: string, error: string }> {
        try {
            errorObj.time = DateTime.now();
            errorObj.msg = errorObj.message;
            errorObj.loc = loc;
            const user = this.userService.getCurrentUser();
            if (user) {
                errorObj.user = `${user.user_id} ${user.nickname}`;
            } else {
                errorObj.user = '<not logged in>';
            }
            const url = environment.BASE_API_URL + environment.SUB_API_URL + '/log/error';
            return this.http.post<{ success: boolean, result: string, error: string }>(url, errorObj, this.locale ? { params: { locale: this.locale } } : undefined);
        } catch (cerr) {
            try {
                this.logger.warn('could not send error, error: ' + cerr);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (cerr2) {
                // give up
            }
        }
    }

    sendError(err: Error, loc: string): void {
        try {
            const currentUser = this.userService.getCurrentUser();
            if (currentUser?.user_id && currentUser.nickname) {
                err.user = currentUser?.user_id + ' ' + currentUser.nickname;
            } else if (currentUser) {
                err.user = JSON.stringify(currentUser);
            } else {
                err.user = '<not logged in>';
            }
            this.errorOccurred(err, loc)
                .pipe(throttleTime(environment.RELOADTHROTTLE))
                .subscribe({
                    next: response => {
                        if (response.success !== true) {
                            this.logger.warn('could not send error, error: ' + response.error);
                        }
                    },
                    error: error => {
                        this.logger.warn(`could not send error, error: ${error.message}: ${error.stack}`);
                    }
                })
        } catch (error) {
            this.logger.warn('could not send error: ' + error);
        }
    }
}
