/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
// import ChartRough from 'chartjs-plugin-rough';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartEvent, TooltipItem } from 'chart.js';
import { NgStyle } from '@angular/common';


@Component({
    standalone: true,
    imports: [BaseChartDirective, NgStyle],
    selector: 'app-age-graph',
    templateUrl: './age-graph.component.html',
})
export class AgeGraphComponent {

    constructor(
        private tr: TranslatorService,
        private router: Router
    ) { }

    @Input() set isSmall(is: boolean) {
        if (this.chart) {
            this.options.scales.y.display = !is;
            this.options.plugins.legend.display = !is;
            this.chart.render();
        }
    }

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    @Input() isDarkmode = false;
    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }

    @Output() haveData = new EventEmitter<boolean>();
    // haveData = false;

    canvasHeight = '350px';

    data: ChartConfiguration<'bar'>['data'];

    // plugins = [ChartRough];

    options: ChartConfiguration<'bar'>['options'] = {
        indexAxis: 'y' as const,
        plugins: {
            datalabels: {
                display: false,
            },
            // roughness: {
            //     roughness: 1,
            //     bowing: 1,
            //     fillStyle: 'hachure',
            //     fillWeight: 0,
            //     hachureAngle: -41,
            //     hachureGap: 2.5,
            //     curveStepCount: 1,
            //     simplification: 0
            // }
            title: {
                display: true,
                text: this.tr.anslate('Age'),
                position: 'top' as const,
            },
            legend: {
                // onClick: null,
                // onClick: (event, legendItem) => {
                //     console.log('click on ' + JSON.stringify(legendItem));
                // },
                display: true,
                position: 'bottom' as const,
                reverse: true,
                // labels: {
                //     generateLabels: chart => {
                //         const data = chart.data;
                //         let dsets = data.datasets;
                //         // we reorder the datasets if they are our 4 as by default first and second element are swapped?!
                //         if (data.datasets.length === 4) {
                //             dsets = [data.datasets[3], data.datasets[2], data.datasets[0], data.datasets[1]];
                //         }
                //         // we filter those that do not have any data
                //         dsets = dsets.filter(function (item) {
                //             return !item.data.every(x => x === 0);
                //         });
                //         return Array.isArray(data.datasets) ? dsets.map((dataset, i) => ({
                //             text: dataset.label,
                //             fillStyle: (!Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0]),
                //             hidden: !chart.isDatasetVisible(i),
                //             // lineCap: 'butt',
                //             // lineDash: [],
                //             // lineDashOffset: 0.0,
                //             // lineJoin: 'miter',
                //             // pointStyle: '-',
                //             // lineCap: dataset.borderCapStyle,
                //             // lineDash: dataset.borderDash,
                //             // lineDashOffset: dataset.borderDashOffset,
                //             // lineJoin: dataset.borderJoinStyle,
                //             // pointStyle: dataset.pointStyle,
                //             // lineWidth: dataset.borderWidth as number,
                //             // strokeStyle: (!Array.isArray(dataset.borderColor) ? dataset.borderColor : dataset.borderColor[0]),
                //             // // we need to copy the chart global and dataset local rough styles
                //             // rough: Object.assign(Object.assign({}, this.chart.options.plugins.rough), dataset.rough),
                //             // Below is extra data used for toggling the datasets
                //             datasetIndex: i,
                //             rotation: 0,
                //             fontColor: Chart.defaults.color as Color,
                //         }))
                //             : [];
                //     }
                // },
            },
            tooltip: {
                mode: 'index' as const,
                position: 'nearest' as const,
                displayColors: false,
                backgroundColor: 'rgba(66,66,66,0.8)',
                titleColor: '#fff',
                bodyColor: 'rgba(0, 0, 0, 0)',
                callbacks: {
                    title: (tooltipItems: TooltipItem<'bar'>[]) => {
                        const index = tooltipItems[0].dataIndex;
                        let header = this.data.labels[index] as string;
                        if (this.data['cropYears'] && this.data['cropYears'][index]) {
                            header = header + ', ' + this.data['cropYears'][index];
                        }
                        return header;
                    },
                    label: (tooltipItem: TooltipItem<'bar'>) => {
                        const datasetIndex = tooltipItem.datasetIndex;
                        const index = tooltipItem.dataIndex;
                        // we access the "unlimited" orgData and not the potentially cut "data" to extract the projection_min/project_max
                        const projection_min = Math.abs(this.data.datasets[0]['orgData'][index]);
                        const projection_max = Math.abs(this.data.datasets[1]['orgData'][index]);
                        const in_stock = Math.round(Math.abs(this.data.datasets[2].data[index] as number));
                        const age_at_purchase = Math.round(Math.abs(this.data.datasets[3].data[index] as number));
                        const age = age_at_purchase + in_stock;
                        const total_min = Math.round(age + projection_min);
                        const total_max = Math.round(age + projection_min + projection_max);

                        if ((datasetIndex === 0) && (in_stock > 0)) {
                            let more_than = '';
                            if (this.data.datasets[2]['left_maxed'][index]) {
                                more_than = this.tr.anslate('more than') + ' ';
                            }
                            return this.tr.anslate('stored') + ': ' + more_than + this.formatMonth(in_stock);
                        } else if ((datasetIndex === 1) && (age_at_purchase > 0)) {
                            const harvest_estimated = this.data.datasets[3]['harvest_estimated'][index];
                            let more_than = '';
                            if (this.data.datasets[3]['left_maxed'][index]) {
                                more_than = this.tr.anslate('more than') + ' ';
                            }
                            if (harvest_estimated) {
                                return this.tr.anslate('estimated age') + ': ' + more_than + this.formatMonth(age);
                            } else {
                                return this.tr.anslate('age') + ': ' + this.formatMonth(age);
                            }
                        } else if ((datasetIndex === 2) && (projection_min > 0 || projection_max > 0)) {
                            if ((total_min < total_max) && ((total_max < 12) || ((Math.round(10 * total_min / 12) / 10) !== Math.round(10 * total_max / 12) / 10))) {
                                const prefix = (((total_min < 12) && (total_max < 12)) || ((total_min > 11) && (total_max > 11)));
                                return this.tr.anslate('expected total age') + ': ' + this.formatMonth(total_min, !prefix) + ' – ' + this.formatMonth(total_max);
                            } else if (total_max > age) {
                                return this.tr.anslate('expected total age') + ': ' + this.formatMonth(total_max);
                            }
                        } else if ((datasetIndex === 3) && (projection_min > 0 || projection_max > 0)) {
                            const min_runout = new Date();
                            min_runout.setMonth(min_runout.getMonth() + projection_min);
                            const min_runout_month = min_runout.getMonth() + 1;
                            const min_runout_year = min_runout.getFullYear();
                            const max_runout = new Date();
                            max_runout.setMonth(max_runout.getMonth() + projection_min + projection_max);
                            const max_runout_month = max_runout.getMonth() + 1;
                            const max_runout_year = max_runout.getFullYear();
                            if ((total_min < total_max) && ((min_runout_year !== max_runout_year) || (min_runout_month !== max_runout_month))) {
                                return this.tr.anslate('expected reach') + ': ' + `${min_runout_month}/${min_runout_year} – ${max_runout_month}/${max_runout_year}`;
                            } else if (total_max > age) {
                                return this.tr.anslate('expected reach') + ': ' + `${max_runout_month}/${max_runout_year}`;
                            }
                        } else {
                            return null;
                        }
                    }
                }
            },
        },
        hover: {
            mode: 'index' as const,
            axis: 'y' as const,
            // animationDuration: 0, // duration of animations when hovering an item
        },
        layout: {
            padding: {
                left: 5,
                right: 30,
                top: 20,
                bottom: 20,
            }
        },
        aspectRatio: 5,
        maintainAspectRatio: false,
        // responsiveAnimationDuration: 500,
        responsive: true,
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    stepSize: 6,
                    autoSkip: true,
                    // maxRotation: 0,
                    maxTicksLimit: 15,
                    callback: (value: number) => {
                        if (value === 0) {
                            return this.tr.anslate('today');
                        } else {
                            return Math.max(1, Math.abs(Math.round(value)));
                        }
                    },
                    major: {
                        enabled: true,
                    },
                },
                title: {
                    display: true,
                    text: this.tr.anslate('Months')
                },
                border: {
                    display: true,
                },
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: '#999',
                    // zeroLineWidth: 2,
                },
                stacked: true,
            },
            y: {
                border: {
                    display: true,
                },
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.25)',
                },
                ticks: {
                    display: true,
                },
                stacked: true,
            },
        },
    };

    adaptGraphSize() {
        try {
            if (this.chart) {
                this.canvasHeight = (this.data.labels.length * 15 + 250) + 'px';
                this.chart.render();
            } else {
                setTimeout(() => {
                    this.canvasHeight = (this.data.labels.length * 15 + 250) + 'px';
                    this.chart?.render();
                });
            }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (err) {
        }
    }

    formatMonth(m: number, postfix = true): string {
        if (m === 1) {
            if (postfix) {
                return this.tr.anslate('1 month');
            } else {
                return '1';
            }
        } else if (m <= 0) {
            return '';
        } else if (m < 12) {
            if (postfix) {
                return this.tr.anslate('{{nrMonth}} months', { nrMonth: m }).trim();
            } else {
                return `${m}`;
            }
        } else {
            const y = Math.round(10 * m / 12) / 10;
            if (y === 1) {
                if (postfix) {
                    return this.tr.anslate('1 year');
                } else {
                    return '1';
                }
            } else {
                if (postfix) {
                    return this.tr.anslate('{{nrYears}} years', { nrYears: y }).trim();
                } else {
                    return `${y}`;
                }
            }
        }
    }

    // ngOnInit(): void {
    //     Chart.pluginService.register(ChartRough);
    // }

    chartClicked(event: { event?: ChartEvent, active?: { datasetIndex?: number, index?: number }[] }) {
        if (event.active?.length > 0) {
            const elem = event.active[0];
            const internal_hr_id = this.data.datasets[elem.datasetIndex]?.['coffees']?.[elem.index];
            if (internal_hr_id) {
                this.router.navigate(['/coffees', { id: 'C' + internal_hr_id }]);
            }
        }
    }

    setNewData(nd: any) {
        this.data = { labels: [], datasets: [] };
        this.data['cropYears'] = [];

        if (nd?.data && nd.labels?.length && nd.additionalData?.length > 4 &&
            nd.coffees?.length === nd.labels.length &&
            nd.data.length === nd.labels.length &&
            nd.labels.length === nd.additionalData[0]?.data?.length &&
            nd.labels.length === nd.additionalData[1]?.data?.length &&
            nd.labels.length === nd.additionalData[2]?.data?.length &&
            nd.labels.length === nd.additionalData[3]?.data?.length) {

            this.haveData.emit(true);

            // we ensure that very large data is cut on the left and right side by setting min/max x-tick limits:

            const leftSums = nd.data.map((a, i) => a + nd.additionalData[2].data[i]);
            const rightSums = nd.additionalData[0].data.map((a: number, i: number) => a + nd.additionalData[1].data[i]);
            // sorted sums of left/negative and right/positive total bar length with zeros removed but for one zero
            const leftSumsSorted = leftSums.concat(0).sort((a: number, b: number) => a - b);
            const rightSumsSorted = rightSums.filter(val => val !== 0).concat(0).sort((a: number, b: number) => a - b);
            // medians of sorted left and right sums
            // tslint:disable-next-line: no-bitwise
            const leftMedian = (leftSumsSorted[(leftSumsSorted.length - 1) >> 1] + leftSumsSorted[leftSumsSorted.length >> 1]) / 2;
            // tslint:disable-next-line: no-bitwise
            const rightMedian = (rightSumsSorted[(rightSumsSorted.length - 1) >> 1] + rightSumsSorted[rightSumsSorted.length >> 1]) / 2;
            // the min/max accepted values, 3x the median, aligned to the 6 month step size
            const leftMax = (Math.round((leftMedian * 3) / 6) + 1) * 6;
            const rightMax = (Math.round((rightMedian * 3) / 6) + 1) * 6;
            // if there are values larger than 3x the median, we set the median as maximum
            if (leftMax < Math.max(...leftSums)) {
                this.options.scales.x.ticks['min'] = -leftMax;
            } else {
                delete this.options.scales.x.ticks['min'];
            }
            if (rightMax < Math.max(...rightSums)) {
                this.options.scales.x.ticks['max'] = rightMax;
            } else {
                delete this.options.scales.x.ticks['max'];
            }
            // list of booleans, indicating cut items
            const leftMaxed = leftSums.map((v: number) => (v > leftMax));
            const rightMaxed = rightSums.map((v: number) => (v > rightMax));

            const dsets = [];
            // add projection min dataset
            dsets.push({
                categoryPercentage: 1,
                barPercentage: 0.6,
                label: this.tr.anslate('min reach'),
                coffees: nd.coffees,
                data: nd.additionalData[0].data.map((n: number) => Math.min(n, rightMax)),
                orgData: nd.additionalData[0].data,
                backgroundColor: this.isDarkmode ? rightMaxed.map((b: boolean) => b ? '#eeeeee85' : '#bdbdbd80') : rightMaxed.map((b: boolean) => b ? '#757575A0' : '#bdbdbdA0'),
                hoverBackgroundColor: this.isDarkmode ? rightMaxed.map((b: boolean) => b ? '#eeeeeeAF' : '#bdbdbdDD') : rightMaxed.map((b: boolean) => b ? '#757575FF' : '#bdbdbdFF'),
                borderColor: this.isDarkmode ? '#bdbdbdAA' : '#bdbdbd',
                hoverBorderColor: this.isDarkmode ? '#bdbdbdEE' : '#bdbdbd',
                // backgroundColor: this.isDarkmode ? rightMaxed.map((b: boolean) => b ? '#eeeeee85' : '#bdbdbd80') : rightMaxed.map((b: boolean) => b ? '#757575A0' : '#bdbdbdA0'),
                // hoverBackgroundColor: this.isDarkmode ? rightMaxed.map((b: boolean) => b ? '#eeeeeeAF' : '#bdbdbdDD') : rightMaxed.map((b: boolean) => b ? '#757575FF' : '#bdbdbdFF'),
                // borderColor: this.isDarkmode ? '#bdbdbdAA' : '#bdbdbd',
                // hoverBorderColor: this.isDarkmode ? '#bdbdbdEE' : '#bdbdbd',
                borderWidth: 1,
                // roughness: {
                //     roughness: 0.7,
                //     fillStyle: 'solid',
                //     bowing: 0.7
                // }
            });
            // add projection max dataset
            dsets.push({
                categoryPercentage: 1,
                barPercentage: 0.6,
                label: this.tr.anslate('max reach'),
                coffees: nd.coffees,
                data: nd.additionalData[1].data.map((n: number, i: number) => Math.min(n, Math.max(0, rightMax - nd.additionalData[0].data[i]))),
                orgData: nd.additionalData[1].data,
                backgroundColor: this.isDarkmode ? rightMaxed.map((b: boolean) => b ? '#eeeeeeAA' : '#bdbdbdA0') : rightMaxed.map((b: boolean) => b ? '#bdbdbdA0' : '#d0d0d0'),
                hoverBackgroundColor: this.isDarkmode ? rightMaxed.map((b: boolean) => b ? '#eeeeeeDD' : '#bdbdbdFF') : rightMaxed.map((b: boolean) => b ? '#bdbdbdFF' : '#c4c1c1'),
                // backgroundColor: this.isDarkmode ? rightMaxed.map((b: boolean) => b ? '#eeeeeeAA' : '#bdbdbdA0') : rightMaxed.map((b: boolean) => b ? '#757575A0' : '#bdbdbdA0'),
                // hoverBackgroundColor: this.isDarkmode ? rightMaxed.map((b: boolean) => b ? '#eeeeeeDD' : '#bdbdbdFF') : rightMaxed.map((b: boolean) => b ? '#757575FF' : '#bdbdbdFF'),
                borderWidth: 0
            });
            // add purchase dataset
            dsets.push({
                categoryPercentage: 1,
                barPercentage: 0.6,
                label: this.tr.anslate('stored'),
                coffees: nd.coffees,
                data: nd.data.map(n => -Math.min(n, leftMax)),
                left_maxed: nd.data.map(n => n > leftMax),
                borderWidth: 1,
                backgroundColor: this.isDarkmode ? leftMaxed.map((b: boolean) => b ? '#e687a8AA' : '#db5785A0') : leftMaxed.map((b: boolean) => b ? '#cc0f50A0' : '#db5785A0'),
                borderColor: this.isDarkmode ? leftMaxed.map((b: boolean) => b ? '#e687a8DF' : '#db5785') : leftMaxed.map((b: boolean) => b ? '#cc0f50' : '#db5785'),
                hoverBackgroundColor: this.isDarkmode ? leftMaxed.map((b: boolean) => b ? '#e687a8CC' : '#db5785DD') : leftMaxed.map((b: boolean) => b ? '#c70d49A2' : '#c70d49A2'),
                hoverBorderColor: this.isDarkmode ? leftMaxed.map((b: boolean) => b ? '#e687a8' : '#db5785') : leftMaxed.map((b: boolean) => b ? '#ad0427' : '#c00b40'),
                // roughness: {
                //     roughness: 0.3,
                //     fillStyle: 'solid',
                //     bowing: 0.5
                // }
            });
            // add harvested dataset
            // note: nd.additionalData[3].data holds the harvest_state (1: estimated, 0: defined)
            dsets.push({
                categoryPercentage: 1,
                barPercentage: 0.6,
                label: this.tr.anslate('harvested'),
                coffees: nd.coffees,
                data: nd.additionalData[2].data.map((n: number, i: number) => -(Math.min(n, Math.max(0, leftMax - nd.data[i])))),
                harvest_estimated: nd.additionalData[3].data.map(n => (n > 0) ? true : false),
                left_maxed: leftMaxed,
                backgroundColor: this.isDarkmode ? nd.additionalData[3].data.map((n: number, i: number) => (leftMaxed[i] ? '#bde0eeBB' : ((n > 0) ? '#bde0ee80' : '#bde0eeBB')))
                    : nd.additionalData[3].data.map((n: number, i: number) => (leftMaxed[i] ? '#1e90c1A0' : ((n > 0) ? '#bde0eeA0' : '#43a7cf70'))),
                hoverBackgroundColor: this.isDarkmode ? nd.additionalData[3].data.map((n: number, i: number) => (leftMaxed[i] ? '#bde0eeDD' : ((n > 0) ? '#bde0eeCC' : '#bde0eeEE')))
                    : nd.additionalData[3].data.map((n: number, i: number) => (leftMaxed[i] ? '#1e90c1DD' : ((n > 0) ? '#bde0eeCC' : '#43a7cfEE'))),
                borderColor: this.isDarkmode ? nd.additionalData[3].data.map((n: number, i: number) => (leftMaxed[i] ? '#bde0eeBB' : ((n > 0) ? '#91cce399' : '#91cce3AA')))
                    : nd.additionalData[3].data.map((n: number, i: number) => (leftMaxed[i] ? '#2298c7' : ((n > 0) ? '#91cce3' : '#43a7cf'))),
                borderWidth: 1,
                hoverBorderColor: this.isDarkmode ? leftMaxed.map((b: boolean) => b ? '#bde0eeFF' : '#bde0ee3FF') : leftMaxed.map((b: boolean) => b ? '#1e90c1' : '#43a7cf'),
            });

            if (this.isDarkmode) {
                this.options.scales.x.grid.color = 'rgba(255, 255, 255, 0.2)';
                // this.options.scales.x.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                this.options.scales.y.grid.color = 'rgba(255, 255, 255, 0.2)';
                // this.options.scales.y.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
                this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
                this.options.plugins.tooltip.backgroundColor = '#eeeeeeEE'; // G200/EE => '#eeeeee'
            } else {
                this.options.scales.x.grid.color = 'rgba(0, 0, 0, 0.1)';
                // this.options.scales.x.grid.zeroLineColor = '#999';
                this.options.scales.y.grid.color = 'rgba(0, 0, 0, 0.1)';
                // this.options.scales.y.grid.zeroLineColor = 'rgba(0, 0, 0, 0.25)';
                this.options.plugins.tooltip.titleColor = '#fff';
                this.options.plugins.tooltip.bodyColor = '#fff';
                this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
            }

            this.data.labels = nd.labels;
            this.data['cropYears'] = nd.additionalData[4]['labels'];
            this.data.datasets = dsets;

            this.adaptGraphSize();
        } else {
            this.haveData.emit(false);
        }
    }
}
